import { PortfolioComposition } from 'utils/types/pcfs';
import { renderValueOrDash } from 'utils/formatting';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { PCFDetailsListColumnConfig } from 'pages/Ledger/PCF/PCFDetails/PCFDetailsAP/tableColumnsConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import { Stack, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';

export interface PCFEtpInformationProps {
  pcf?: PortfolioComposition;
  isLoading?: boolean;
}

function PCFEtpInformation({ pcf, isLoading }: PCFEtpInformationProps) {
  return (
    <Card
      header={
        <Stack padding={2}>
          <Typography variant="subheadingMedium">{'ETP Information'}</Typography>
          <Typography variant="bodyMedium">{pcf?.product?.name}</Typography>
        </Stack>
      }
      headerDivider={false}
      body={
        <Table noPadding>
          <TableHeaderWithMultiSort columns={PCFDetailsListColumnConfig} />
          <TableBodyWithStates
            loadingData={isLoading}
            hasContent={!!pcf}
            noContentLabel="No PCF data."
          >
            <PCFEtpInformationRow pcf={pcf} />
          </TableBodyWithStates>
        </Table>
      }
    />
  );
}

export default PCFEtpInformation;

const PCFEtpInformationRow = ({ pcf }: { pcf?: PortfolioComposition }) => {
  return (
    <>
      <TableRow>
        <TableCell>Currency</TableCell>
        <TableCell>{pcf?.product.baseCurrency}</TableCell>
        <TableCell>{pcf?.product.baseCurrency}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Product ISIN</TableCell>
        <TableCell>{pcf?.product.isin}</TableCell>
        <TableCell>{pcf?.product.isin}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Product Name</TableCell>
        <TableCell>{pcf?.product.name}</TableCell>
        <TableCell>{pcf?.product.name}</TableCell>
      </TableRow>
      {pcf?.currencyPrices?.map((currencyPrice) => (
        <TableRow key={currencyPrice.baseCurrency}>
          <TableCell>Total NAV {currencyPrice.baseCurrency}</TableCell>
          <TableCell
            error={
              pcf.errors?.totalNav?.errors?.find(
                (error) => error.currency === currencyPrice.baseCurrency
              )
                ? 'error'
                : ''
            }
          >
            {renderValueOrDash(
              pcf?.synthetic?.totals?.find((total) => total.currency === currencyPrice.baseCurrency)
                ?.netAssetValue
            )}
          </TableCell>
          <TableCell
            error={
              pcf.errors?.totalNav?.errors?.find(
                (error) => error.currency === currencyPrice.baseCurrency
              )
                ? 'error'
                : ''
            }
          >
            {renderValueOrDash(
              pcf?.official?.totalNav?.find(
                (total) => total.currency === currencyPrice.baseCurrency
              )?.value
            )}
          </TableCell>
        </TableRow>
      ))}
      <TableRow>
        <TableCell>Total Units Outstanding</TableCell>
        <TableCell error={pcf?.errors?.totalUnitsOutstanding?.length ? 'error' : ''}>
          {pcf?.synthetic?.totalUnitsOutstanding || '-'}
        </TableCell>
        <TableCell error={pcf?.errors?.totalUnitsOutstanding?.length ? 'error' : ''}>
          {pcf?.official?.totalUnitsOutstanding || '-'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Creation Unit Size</TableCell>
        <TableCell>{pcf?.product?.unitSize}</TableCell>
        <TableCell>{pcf?.official?.product.unitSize}</TableCell>
      </TableRow>
    </>
  );
};
