import { default as MuiCard } from '@mui/material/Card';
import { Box, CardContent, CardActions, Divider, CircularProgress } from '@mui/material';
import { ReactNode } from 'react';
import { ReactComponent as CloseIcon } from 'assets/close.svg';

interface CardProps extends React.PropsWithChildren {
  classes?: string;
  header?: ReactNode;
  headerDivider?: boolean;
  body?: ReactNode;
  footer?: ReactNode;
  maxCardHeight?: string;
  maxBodyHeight?: string;
  footerDivider?: boolean;
  onClose?: () => void;
  sx?: {
    [key: string]: any;
  };
  sxBody?: {
    [key: string]: any;
  };
  noPadding?: Boolean;
  boxShadow?: Boolean;
  isLoading?: boolean;
}

export const Card = ({
  classes,
  header,
  headerDivider = true, // useful for cards that wrap tables as the divider may not be needed
  body,
  footer,
  maxCardHeight,
  maxBodyHeight,
  footerDivider = true, // useful for cards that wrap tables as the divider may not be needed
  onClose,
  noPadding = false, // use true when Card requires header & footer (to accommodate the dividers)
  isLoading = false,
  sx,
  sxBody,
  boxShadow = true,
}: CardProps) => {
  return (
    <MuiCard
      classes={classes}
      sx={{
        maxHeight: maxCardHeight,
        padding: !noPadding ? '24px' : '0',
        boxShadow: boxShadow
          ? '0px 21px 40px 0px rgba(27, 36, 50, 0.04), 0px 7.1px 12.1px 0px rgba(27, 36, 50, 0.02), 0px 0px 3px 0px rgba(27, 36, 50, 0.08), 0px 0.9px 1.8px 0px rgba(27, 36, 50, 0.02), 0px 2.8px 5px 0px rgba(27, 36, 50, 0.01)'
          : 'none',
        ...sx,
      }}
    >
      {onClose && (
        <Box
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '16px',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </Box>
      )}
      {header && (
        <>
          <Box minHeight={'40px'}>{header}</Box>
          {headerDivider && (
            <Divider
              sx={{
                width: '100%',
              }}
            />
          )}
        </>
      )}
      {body && (
        <Box sx={{ position: 'relative' }}>
          <CardContent sx={{ maxHeight: maxBodyHeight, ...sxBody }}>{body}</CardContent>
          {isLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
      {footer && (
        <>
          {footerDivider && (
            <Divider
              sx={{
                width: '100%',
              }}
            />
          )}
          <CardActions sx={{ padding: '0' }}>{footer}</CardActions>
        </>
      )}
    </MuiCard>
  );
};
