import Product from 'components/Product';
import { Checkbox, CheckboxProps } from 'components/BooleanInputs';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { Stack, Typography } from '@mui/material';
import { UnrealizedAccruedFee } from 'utils/types/fees';
import { TableHeaderColumn } from 'components/Table/Table.types';

type FeeTableRowProps = {
  fee: UnrealizedAccruedFee;
  editAction?: () => void;
  showActionsCell: boolean;
  checkboxProps?: CheckboxProps;
  columns: TableHeaderColumn[];
};

const renderCell = (fee: UnrealizedAccruedFee, column: TableHeaderColumn) => {
  switch (column.propName) {
    case 'product.ticker':
      return (
        <TableCell nowrap>
          <Stack direction="row" spacing={2}>
            <Product instrument={fee.product} />
          </Stack>
        </TableCell>
      );
    case 'constituent':
      return (
        <TableCell data-qa-id={`${fee.constituentAsset.ticker}`}>
          <Typography>{fee.constituentAsset.ticker ?? '-'} </Typography>
        </TableCell>
      );
    case 'totalUnrealizedAccruedFee':
      return (
        <TableCell data-qa-id={`${fee.totalUnrealizedAccruedFee}`}>
          <Typography>{fee.totalUnrealizedAccruedFee ?? '-'} </Typography>
        </TableCell>
      );
  }
};

export const FeesTableRow = ({ fee, editAction, checkboxProps, columns }: FeeTableRowProps) => {
  const id = `${fee.product._id}_${fee.constituentAsset.ticker}`;
  return (
    <TableRow data-qa-id={`${id}-row`} key={id} onClick={editAction}>
      {checkboxProps && (
        <TableCell
          padding="checkbox"
          data-qa-id={`${id}-checkbox`}
          onClick={(event) => event.stopPropagation()}
        >
          <Checkbox {...checkboxProps} />
        </TableCell>
      )}
      {columns.filter((col) => !col.hideColumn).map((col) => renderCell(fee, col))}
    </TableRow>
  );
};
