export const FeesDetailsTableColumnsConfig = [
  {
    label: 'Transaction',
    propName: 'transaction',
    propType: 'string',
    width: '60%',
    sort: false,
  },
  {
    label: 'Date',
    propName: 'date',
    propType: 'date',
    sort: false,
    width: 'auto',
  },
  {
    label: 'Amount',
    propName: 'amount',
    propType: 'string',
    width: 'auto',
    sort: false,
  },
];
