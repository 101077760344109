import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { Partner } from 'utils/types';
import { PartnerTypeTitles } from 'utils/constants/partners';
import { dateFormat } from 'shared/Tables/table.utils';
import { format, parseISO } from 'date-fns';
import Tag from 'components/Tag/Tag';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

type PatnerTableRowProps = {
  partner: Partner;
  editAction?: () => void;
  deleteAction?: () => void;
  showActionsCell: boolean;
};

const PartnerTableRow = ({
  partner,
  editAction,
  deleteAction,
  showActionsCell,
}: PatnerTableRowProps) => {
  return (
    <TableRow data-qa-id={`${partner.name}-row`} key={partner.name} onClick={editAction}>
      <TableCell data-qa-id={`${partner.name}-name`}>{partner.name}</TableCell>
      <TableCell data-qa-id={`${partner.name}-partnerType`}>
        <Tag label={PartnerTypeTitles[partner.type]} variant="yellow" />
      </TableCell>
      <TableCell data-qa-id={`${partner.name}-updated`}>
        {partner.updatedAt && format(parseISO(partner.updatedAt), dateFormat)}
      </TableCell>
      <TableCell
        data-qa-id="actionBtn"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {showActionsCell && partner.status !== 'DELETED' && (
          <>
            {(partner._actions?.update || partner._actions?.delete) && (
              <ButtonDropdown id={partner._id ?? partner.name} className="actionsCell">
                {partner._actions?.update && (
                  <ButtonDropdownItem
                    id="editPartner"
                    label="Edit Partner"
                    onClick={editAction ? editAction : () => {}}
                  />
                )}
                {partner._actions?.delete && (
                  <ButtonDropdownItem
                    id="deletePartner"
                    label="Delete Partner"
                    onClick={deleteAction ? deleteAction : () => {}}
                  />
                )}
              </ButtonDropdown>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default PartnerTableRow;
