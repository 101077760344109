import Filters from 'components/Filters';
import ProductSelector from 'components/ProductSelector';
import { useUserPermissions } from 'store/user/selectors';

import {
  ORDERS_TAB_ALL_STATUSES,
  OrderTabType,
  OrdersFiltersData,
  tabInProgress,
} from 'utils/constants/orders';
import { OptionType } from 'components/Select/Select.types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useOrdersTab } from 'pages/Orders/Orders/hooks/useOrdersTab';
import { ProductType } from 'utils/types/product';
import TokenProductSelector from 'components/TokenProductSelector';
import { useCompaniesSuggestion } from 'utils/api/hooks/useCompaniesSuggestion.hook';
import Select from 'components/Select';
import { styled as MuiStyled } from '@mui/material';

const statusDefaultValue = {
  label: 'All Statuses',
  value: '',
};

const companyDefaultValue = {
  label: 'All Companies',
  value: '',
};

type Props = {
  productType: ProductType;
  setUrlParams: React.Dispatch<React.SetStateAction<OrdersFiltersData>>;
  urlParams: OrdersFiltersData;
};

const StyledFilterSelect = MuiStyled(Select)`
  width: 240px;
  text-transform: capitalize;
  .select-options-container {
    margin-top: -${({ theme }) => theme.spacers['spacing-02']};
    right: 0;
  }
`;

export const OrdersFilters = ({ productType, setUrlParams, urlParams }: Props) => {
  const permissions = useUserPermissions();
  const { data: partners, isLoading } = useCompaniesSuggestion(
    undefined,
    Boolean(permissions?.isIssuer || permissions?.isSystemAdmin)
  );
  const isSetQueryCompanyFilterValue = useRef(false);

  const partnersOptions = useMemo(
    () => partners?.map((partner) => ({ label: partner.name, value: partner._id ?? '' })) ?? [],
    [partners]
  );
  const { tab } = useOrdersTab();

  const ordersListOptions = ORDERS_TAB_ALL_STATUSES[urlParams.tab as OrderTabType]
    ? ORDERS_TAB_ALL_STATUSES[urlParams.tab as OrderTabType]
    : ORDERS_TAB_ALL_STATUSES[tabInProgress];

  const companyDefault = useMemo(
    () => partnersOptions.find(({ value }) => value === urlParams.company) ?? companyDefaultValue,
    [urlParams.company, partnersOptions]
  );

  const statusDefault = useMemo(
    () => ordersListOptions.find(({ value }) => value === urlParams.status) ?? statusDefaultValue,
    [urlParams.status, ordersListOptions]
  );

  const [statusFilter, setStatusFilter] = useState<OptionType<string>>(statusDefault);
  const [companyFilter, setCompanyFilter] = useState<OptionType<string>>(companyDefault);
  const [selectedProduct, setSelectedProduct] = useState<string>(urlParams.product ?? '');

  useEffect(() => {
    if (partnersOptions.length && !isLoading && !isSetQueryCompanyFilterValue.current) {
      setCompanyFilter(companyDefault);
      isSetQueryCompanyFilterValue.current = true;
    }
  }, [isLoading, partnersOptions, companyDefault]);

  useEffect(() => {
    setStatusFilter(statusDefault);
  }, [statusDefault, tab]);

  return (
    <Filters
      onReset={() => {
        setStatusFilter(statusDefault);
        setCompanyFilter(companyDefaultValue);
        setSelectedProduct('');
        setUrlParams((prevState) => ({
          ...prevState,
          product: null,
          status: null,
          company: null,
        }));
      }}
      onApply={() =>
        setUrlParams((prevState) => ({
          ...prevState,
          product: selectedProduct,
          status: statusFilter.value,
          company: companyFilter.value,
        }))
      }
    >
      {!permissions?.isIssuer && (
        <StyledFilterSelect
          className="filter-select"
          filterable
          label="Company"
          options={[companyDefaultValue, ...partnersOptions]}
          value={companyFilter?.value}
          onChange={(newValue) => {
            const selectedOption =
              partnersOptions.find((ord) => ord.value === newValue) ?? companyDefaultValue;
            setCompanyFilter(selectedOption);
          }}
        />
      )}
      {productType === 'ETP' ? (
        <ProductSelector
          label="Product"
          clearable
          onChange={(product) => setSelectedProduct(product ?? '')}
          value={selectedProduct}
        />
      ) : (
        <TokenProductSelector
          label="Product"
          clearable
          onChange={(product) => setSelectedProduct(product ?? '')}
          value={selectedProduct}
        />
      )}
      <StyledFilterSelect
        filterable
        label="Status"
        options={[statusDefaultValue, ...ordersListOptions]}
        value={statusFilter?.value}
        onChange={(newValue) => {
          const selectedOption =
            ordersListOptions.find((ord) => ord.value === newValue) ?? statusDefaultValue;
          setStatusFilter(selectedOption);
        }}
      />
    </Filters>
  );
};
