import Table, { TableCell, TableRow } from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { Deliverable, Order } from 'utils/types/orders';
import { ProductType } from 'utils/types';
import { TableBody, Typography } from '@mui/material';
import { formatToCurrency } from 'utils/formatting';
import TooltipContent from 'components/Tooltip/TooltipContent';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import BigNumber from 'bignumber.js';

interface ExpectedDeliverablesExpectedTableProps {
  productType: ProductType;
  order?: Order;
}

const ExpectedDeliverablesTable = ({
  productType,
  order,
}: ExpectedDeliverablesExpectedTableProps) => {
  const deliverables = order?.deliveries?.expected;

  const etpColumns = [
    { label: 'Asset', propName: 'asset', propType: 'string', sort: false },
    { label: 'Weight', propName: 'weight', propType: 'string', sort: false },
    { label: 'Quantity', propName: 'quantity', propType: 'string', sort: false },
    {
      label: 'Reference Price',
      propName: 'referencePrice',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title="Reference price"
          description={`T-1 Price from PCF with valuation date ${
            deliverables &&
            deliverables[0]?.priceAt &&
            format(new Date(deliverables[0]?.priceAt), DEFAULT_DATE_FORMAT)
          }. This PCF was effective at the time of this order`}
        />
      ),
    },
    { label: 'Market Value', propName: 'marketValue', propType: 'string', sort: false },
    {
      label: 'Expected delivery',
      propName: 'expectedDelivery',
      propType: 'string',
      sort: false,
      tooltip: order?.deliveryType === 'CASH' && (
        <TooltipContent
          title={`USD/USDC: ${deliverables && deliverables[0]?.fxRate}`}
          description={`The FX rate between base currency and delivery currency is ${
            deliverables && deliverables[0]?.fxRate
          }`}
        />
      ),
    },
  ];

  const tokenColumns = [
    { label: 'Asset', propName: 'ticker', propType: 'string' },
    { label: 'Quantity', propName: 'quantity', propType: 'number' },
  ];

  return (
    <>
      {productType === 'ETP' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort columns={etpColumns} />
          <TableBody>
            {deliverables?.length ? (
              deliverables
                ?.filter((deliverable) => Boolean(deliverable.weight))
                .map((deliverable: Deliverable) => (
                  <TableRow key={deliverable.ticker} hoverEffects={false}>
                    {/* Asset */}
                    <TableCell key="ticker">{deliverable?.ticker}</TableCell>
                    {/* Weight */}
                    <TableCell key="weight">
                      {deliverable?.weight
                        ? `${new BigNumber(deliverable?.weight)
                            .multipliedBy(100)
                            .toFixed(2, BigNumber.ROUND_HALF_UP)}%`
                        : '-'}
                    </TableCell>
                    {/* Quantity */}
                    <TableCell key="coinQty">{deliverable?.amount}</TableCell>
                    {/* Reference Price */}
                    <TableCell key="priceT1Nav">
                      {formatToCurrency(deliverable.price, deliverable?.currency)}
                    </TableCell>
                    {/* Market Value */}
                    <TableCell key="value">
                      {formatToCurrency(deliverable.totalInCurrency, deliverable?.currency)}
                    </TableCell>
                    {/* Actual Delivery */}
                    <TableCell key="expectedTotalWithCash">
                      {order?.deliveryType === 'CASH'
                        ? formatToCurrency(
                            deliverable.totalInDeliveryCurrency,
                            order?.deliveryCurrency
                          )
                        : formatToCurrency(deliverable.amount, deliverable.ticker)}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow hoverEffects={false}>
                {/* Asset */}
                <TableCell key="ticker">
                  <Typography variant="labelMedium">Total</Typography>
                </TableCell>
                {/* Weight */}
                <TableCell key="weight">-</TableCell>
                {/* Quantity */}
                <TableCell key="coinQty">-</TableCell>
                {/* Reference Price */}
                <TableCell key="priceT1Nav">-</TableCell>
                {/* Market Value */}
                <TableCell key="value">-</TableCell>
                {/* Actual Delivery */}
                <TableCell key="expectedTotalWithCash">-</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {productType === 'Token' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort
            columns={tokenColumns}
            columnAlignment={{ quantity: 'right' }}
          />
          <TableBody>
            {deliverables?.length ? (
              deliverables?.map((deliverable: Deliverable, index) => (
                <TableRow key={index} hoverEffects={false}>
                  <TableCell key="ticker">{deliverable?.ticker}</TableCell>
                  <TableCell align="right" key="coinQty">
                    {deliverable.amount}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow hoverEffects={false}>
                <TableCell key="ticker">-</TableCell>
                <TableCell align="right" key="cointQty">
                  -
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default ExpectedDeliverablesTable;
