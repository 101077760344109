import { Theme } from '@mui/material/styles';

export function table(theme: Theme) {
  return {
    // Table
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            whiteSpace: 'nowrap',
          },
          '& .MuiTableCell-root': {
            whiteSpace: 'nowrap',
            color: theme.palette.text.primary,
            ...theme.typography.labelMedium,
            borderBottom: '1px solid #F0F4F7', // @TODO useTheme dividerStandard
          },
        },
      },
    },
    // Table Head
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.customBackground.default.strong,
          '& .MuiTableRow-root': {
            height: '48px',
          },
          '& .MuiTableCell-root': {
            ...theme.typography.labelMediumStrong,
            border: 'none',
          },
          '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          },
          '& .MuiTableRow-root .MuiTableCell-root:last-of-type': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
          },
        },
      },
    },
    // Table Body
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            height: '40px',
          },
        },
      },
    },
    // Table Row
    MuiTableRow: {
      styleOverrides: {
        root: {
          '.editIcons': {
            display: 'none',
          },
          '.actionsCell': {
            visibility: 'hidden',
          },
        },
      },
    },
    // Table Cell
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
        },
      },
    },
  };
}
