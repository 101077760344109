import { TableRow } from 'components/Table/TableRow';
import { TABLE_CELL_ALIGNMENTS } from 'shared/Tables/table.utils';
import { TableHeaderColumn } from 'components/Table/Table.types';
import { useState } from 'react';
import { Checkbox, CheckboxProps } from 'components/BooleanInputs';
import { MuiStyledTableCellChevron } from 'shared/Tables/ContactsTable/ContactsTable.styles';
import { ReactComponent as Ascending } from 'assets/ascending.svg';
import { ReactComponent as Descending } from 'assets/descending.svg';
import { ReactComponent as ExchangeIcon } from 'assets/exchange.svg';
import Tooltip from 'components/Tooltip';
import { TableHead } from '@mui/material';
import { styled as MuiStyled } from '@mui/material';
import { TableCell } from './TableCell';

const NO_INDEX = -1;

interface TableHeaderWithMultiSortProps {
  columns: TableHeaderColumn[];
  columnAlignment?: { [key: string]: TABLE_CELL_ALIGNMENTS };
  onColumnSort?: (columns: string[]) => void;
  defaultSortedColumns?: string[] | string;
  showActionsCell?: boolean;
  checkboxProps?: CheckboxProps;
  showChevronColumn?: boolean;
  toolTipBgColor?: string;
}

interface StyledIconProps {
  hovered?: string | undefined;
}

const MuiStyledExchangeIcon = MuiStyled(ExchangeIcon)<StyledIconProps>(({ theme, hovered }) => ({
  display: hovered ? 'inline-block' : 'none',
  marginLeft: theme.spacers['spacing-04'],
  width: '12px',
  height: '12px',
  fill: hovered ? theme.palette.grey[700] : theme.palette.grey[400],
}));

const MuiStyledAscendingIcon = MuiStyled(Ascending)<StyledIconProps>(({ theme, hovered }) => ({
  width: '12px',
  height: '12px',
  fill: hovered ? theme.palette.grey[700] : theme.palette.grey[400],
}));

const MuiStyledDescendingIcon = MuiStyled(Descending)<StyledIconProps>(({ theme, hovered }) => ({
  width: '12px',
  height: '12px',
  fill: hovered ? theme.palette.grey[700] : theme.palette.grey[400],
}));

const TableHeaderWithMultiSort = ({
  checkboxProps,
  columnAlignment = {},
  columns,
  defaultSortedColumns = [],
  onColumnSort,
  showActionsCell,
  showChevronColumn,
  toolTipBgColor = 'black',
}: TableHeaderWithMultiSortProps) => {
  const [sortedColumns, setSortedColumns] = useState<string[]>(
    Array.isArray(defaultSortedColumns) ? defaultSortedColumns : [defaultSortedColumns]
  );

  const [hoveredCellIndex, setHoveredCellIndex] = useState<number | null>(null);

  const findColumnIndex = (columns: string[], col: TableHeaderColumn, order: 'asc' | 'desc') => {
    return columns.findIndex((prop) => prop === `${col.propName}:${order}`);
  };

  const handleColumnClick = (col: TableHeaderColumn) => {
    if (onColumnSort && col.sort !== false) {
      const sortedAsc = findColumnIndex(sortedColumns, col, 'asc');
      const sortedDesc = findColumnIndex(sortedColumns, col, 'desc');

      let columnsTemp = [...sortedColumns];

      if (sortedDesc === NO_INDEX && sortedAsc === NO_INDEX) {
        columnsTemp = [...columnsTemp, `${col.propName}:asc`];
      } else if (sortedAsc > NO_INDEX) {
        columnsTemp[sortedAsc] = `${col.propName}:desc`;
      } else if (sortedDesc > NO_INDEX) {
        columnsTemp = columnsTemp.filter((column) => column !== `${col.propName}:desc`);
      }

      onColumnSort && onColumnSort(columnsTemp);
      setSortedColumns(columnsTemp);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {checkboxProps && (
          <TableCell>
            <Checkbox {...checkboxProps} />
          </TableCell>
        )}
        {showChevronColumn && <MuiStyledTableCellChevron />}
        {columns
          .filter((col) => !col.hideColumn)
          .map((col, index) => {
            const isLabelEmpty = !col.label || col.label.length === 0;
            const isSortable = !isLabelEmpty && col.sort !== false;

            return (
              <TableCell
                key={`${col.label || 'empty_label'}_${index}`}
                align={columnAlignment[col.propName] ?? 'left'}
                onClick={() => isSortable && handleColumnClick(col)}
                colSpan={col.colSpan}
                hideOnSmallScreen={col.hideOnSmallScreen}
                onMouseEnter={() => setHoveredCellIndex(index)}
                onMouseLeave={() => setHoveredCellIndex(null)}
                style={{
                  cursor: isSortable ? 'pointer' : undefined,
                  width: col.width,
                  maxWidth: col.maxWidth ? col.maxWidth : undefined,
                  minWidth: col.minWidth,
                }}
              >
                {col.label}
                {isSortable &&
                  findColumnIndex(sortedColumns, col, 'asc') === NO_INDEX &&
                  findColumnIndex(sortedColumns, col, 'desc') === NO_INDEX && (
                    <MuiStyledExchangeIcon
                      hovered={index === hoveredCellIndex ? 'true' : undefined}
                    />
                  )}
                {findColumnIndex(sortedColumns, col, 'asc') > NO_INDEX && (
                  <MuiStyledAscendingIcon
                    hovered={index === hoveredCellIndex ? 'true' : undefined}
                  />
                )}
                {findColumnIndex(sortedColumns, col, 'desc') > NO_INDEX && (
                  <MuiStyledDescendingIcon
                    hovered={index === hoveredCellIndex ? 'true' : undefined}
                  />
                )}
                {col.tooltip && (
                  <Tooltip
                    style={{ marginLeft: '4px ' }}
                    placement="bottom"
                    title={col.tooltip}
                    inline
                    wide
                    bgColor={toolTipBgColor}
                    arrow
                  />
                )}
              </TableCell>
            );
          })}
        {showActionsCell && (
          <TableCell
            style={{
              width: '8%',
            }}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaderWithMultiSort;
