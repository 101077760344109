import { ReactNode } from 'react';
import CircularProgress from 'components/CircularProgress';
import { TableBody } from '@mui/material';
import { EmptyRow } from './EmptyTableRow';

interface TableBodyWithStatesProps {
  children: ReactNode;
  loadingData?: Boolean;
  hasContent?: Boolean;
  noContentLabel?: string | ReactNode;
  'data-qa-id'?: string;
}

const TableBodyWithStates = ({
  children,
  loadingData,
  hasContent,
  noContentLabel = 'No content.',
  ...props
}: TableBodyWithStatesProps) => {
  const muiContent = hasContent ? children : <EmptyRow>{noContentLabel}</EmptyRow>;

  return (
    <TableBody {...props}>
      {loadingData ? (
        <EmptyRow>
          <CircularProgress />
        </EmptyRow>
      ) : (
        muiContent
      )}
    </TableBody>
  );
};

export default TableBodyWithStates;
