import { CompanySuggestionDto } from 'utils/types';
import { ProductCustodianWallet } from 'utils/types/wallets';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

export type ETPWalletsTableRowProps = {
  wallet: ProductCustodianWallet;
  custodianName: string;
  partners: CompanySuggestionDto[];
  editAction: () => void;
  deleteAction: () => void;
  activateAction: () => void;
};

export const ETPWalletsTableRow = ({
  wallet,
  partners,
  custodianName,
  editAction,
  deleteAction,
  activateAction,
}: ETPWalletsTableRowProps) => {
  return (
    <TableRow
      key={wallet._id}
      onClick={() => {
        wallet?._actions?.update && editAction();
      }}
    >
      <TableCell>{wallet.description}</TableCell>
      <TableCell>{wallet.chain}</TableCell>
      <TableCell>{custodianName}</TableCell>
      <TableCell>
        {partners.find((partner) => partner._id === wallet.transactingCompany)?.name}
      </TableCell>
      <TableCell
        align="right"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {wallet?._actions && Object.keys(wallet?._actions) && (
          <ButtonDropdown id={wallet._id ?? ''} className="actionsCell">
            {wallet?._actions?.activate && (
              <ButtonDropdownItem
                id="activateWallet"
                label="Activate wallet"
                onClick={activateAction}
              />
            )}
            {wallet?._actions?.delete && (
              <ButtonDropdownItem
                id="deleteWallet"
                label="Deactivate wallet"
                onClick={deleteAction}
              />
            )}
          </ButtonDropdown>
        )}
      </TableCell>
    </TableRow>
  );
};
