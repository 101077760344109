import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { TitleArea } from 'components/TitleArea/TitleArea';
import Pagination from 'components/Pagination';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import { Card } from 'components/Card/Card';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { usePusherInitializer } from 'hooks/usePusherInitializer';
import { useUrlParams } from 'hooks/useUrlParams';
import OrdersTableToolbar from 'pages/Orders/Orders/components/OrdersToolbar';
import { useGenerateOrdersCsvFile } from 'pages/Orders/Orders/hooks/useGenerateOrdersCsvFile'; // TODO TOKENS - do we need csv downloads?
import { useLoadOrders } from 'pages/Orders/Orders/Order/hooks/useLoadOrders'; // TODO TOKENS - change out for use "Tokens page" hook
import createOrdersTableTitle from 'pages/Orders/Orders/utils/createOrdersTableTitle';
import { privateRoutesUrls } from 'router/constants';
import Button from 'components/Button';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { OrdersTable } from 'shared/Tables/OrdersTable/OrdersTable';
import { useUserPermissions } from 'store/user/selectors';
import { ORDER_TABS, OrderTabType, defaultOrdersFiltersData } from 'utils/constants/orders';
import { Order, PaginationResponse } from 'utils/types';
import { compileUrl } from 'utils/url';

export const OrdersTokens = () => {
  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    defaultOrdersFiltersData,
    privateRoutesUrls.dashboardRoutes.ordersTokens
  );

  const [paginationResponse, setPaginationResponse] = useState<PaginationResponse>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_ITEMS_PER_PAGE,
    total: 0,
  });

  const [orders, setOrders] = useState<any[]>([]);
  const { data: ordersData, isLoading: loading, refetch } = useLoadOrders(urlParams, 'Token');

  const handleOrdersData = (ordersData: { orders: Order[]; pagination: PaginationResponse }) => {
    setOrders(ordersData.orders ?? []);
    if (ordersData.pagination) {
      setPaginationResponse((prevState: PaginationResponse) => ({
        page: ordersData.pagination.page ?? prevState.page,
        pageSize: ordersData.pagination.pageSize ?? prevState.pageSize,
        total: ordersData.pagination.total ?? prevState.total,
      }));
    }
  };

  useEffect(() => {
    if (ordersData) {
      handleOrdersData(ordersData);
    }
  }, [ordersData]);

  const pagination = useBackendPagination(paginationResponse, setUrlParams);

  const handleOrderUpdate = useCallback(() => {
    refetch().then(() => console.log(`Orders (Token) has been refreshed`));
  }, [refetch, ordersData]);
  usePusherInitializer(handleOrderUpdate);

  const [generateCSVFile] = useGenerateOrdersCsvFile(urlParams, 'Token');
  const navigate = useNavigate();
  const permissions = useUserPermissions();

  const generateOrdersAsXls = () => {
    generateCSVFile().then(() => {});
  };

  const titleActions = (
    <OrdersTableToolbar
      productType={'Token'}
      generateOrdersAsXls={generateOrdersAsXls}
      setUrlParams={setUrlParams}
      urlParams={urlParams}
      disableDownloadButton={!Boolean(orders?.length)}
    />
  );

  const createOwnOrderButton = permissions?.canCreateTokenOrder && (
    <Button
      onClick={() => navigate(privateRoutesUrls.dashboardRoutes.ordersTokensNew)}
      type="button"
      data-qa-id="createOrderButton"
      size="medium"
      key="toolbar-button"
    >
      <span>Create Order</span>
      <PlusIcon />
    </Button>
  );

  const delegateOrderButton = permissions?.canCreateTokenOrderDelegated && (
    <Button
      onClick={() => navigate(privateRoutesUrls.dashboardRoutes.ordersTokensDelegated)}
      type="button"
      data-qa-id="createDelegatedOrderButton"
      size="medium"
      key="toolbar-button"
    >
      <span>New Delegated Order</span>
      <PlusIcon />
    </Button>
  );

  const createOrderButton = delegateOrderButton || createOwnOrderButton;
  const tabs = ORDER_TABS.map((tab) => ({ name: tab[0], param: tab[1] }));
  return (
    <>
      <TitleArea
        title="Token Orders"
        actionButton={createOrderButton}
        tabConfig={{
          tabs: tabs,
          setUrlParamsWithDefaults: (data) => {
            setUrlParamsWithDefaults(data);
          },
          active: urlParams.tab as OrderTabType,
        }}
      />
      <Card
        header={
          <TableTitleWithActions
            title={createOrdersTableTitle('Token', urlParams)}
            actions={[titleActions]}
          />
        }
        headerDivider={false}
        body={
          <OrdersTable
            productType="Token"
            orders={orders ?? []}
            loadingData={loading}
            setUrlParams={setUrlParams}
            urlParams={urlParams}
            // TODO: make sure the user has permissions to edit the order, move it to variable
            editAction={(order) => {
              const editOrderUrl = compileUrl(
                privateRoutesUrls.dashboardRoutes.ordersTokensDetails,
                {
                  label: 'id',
                  value: String(order._id!),
                }
              );
              navigate(editOrderUrl);
            }}
            deleteAction={() => {}}
          />
        }
        footerDivider={false}
        footer={<Pagination {...pagination} />}
      />
    </>
  );
};

export default OrdersTokens;
