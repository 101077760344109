import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';
import { red, green, blue, yellow, neutral } from 'theme/colors/baseColors';
import { NavigationVariantsInterface } from 'theme/themeTokens/types/ThemeComponentTypes';
import { notificationsColorRange } from 'theme/utils/paletteColors';

export const navigationPalette = (themeTokens: ThemeTokens): NavigationVariantsInterface => ({
  primary: {
    highEmphasis: {
      color: themeTokens.static.text.default.standard,
      backgroundColor: notificationsColorRange.backgroundColor.primary,
      border: `1.25px solid ${themeTokens.static.text.divider.default.weak}`,
      iconColor: themeTokens.static.support.strong.neutral,
      // TODO: reuse / delete when colors are updated
      // iconBackgroundColor: themeTokens.static.background.default.strong,
      iconBackgroundColor: neutral[20],
    },
    lowEmphasis: {
      color: themeTokens.static.text.default.standard,
      backgroundColor: themeTokens.static.background.default.weak,
      border: `1.25px solid ${themeTokens.interactive.outline.secondary.default}`,
      iconColor: themeTokens.static.support.strong.neutral,
      iconBackgroundColor: themeTokens.static.background.default.strong,
    },
  },
  success: {
    highEmphasis: {
      color: themeTokens.static.text.sentiment.positive,
      backgroundColor: notificationsColorRange.backgroundColor.success,
      border: `1.25px solid ${themeTokens.static.support.weak.positive}`,
      iconColor: themeTokens.static.support.strong.positive,
      // TODO: reuse / delete when colors are updated
      // iconBackgroundColor: themeTokens.static.support.standard.positive,
      iconBackgroundColor: green[20],
    },
    lowEmphasis: {
      color: themeTokens.static.text.sentiment.positive,
      backgroundColor: themeTokens.static.background.default.weak,
      border: `1.25px solid ${themeTokens.static.support.standard.positive}`,
      iconColor: themeTokens.static.support.strong.positive,
      iconBackgroundColor: themeTokens.static.support.weak.positive,
    },
  },
  error: {
    highEmphasis: {
      color: themeTokens.static.text.sentiment.negative,
      backgroundColor: notificationsColorRange.backgroundColor.error,
      border: `1.25px solid ${themeTokens.static.support.weak.negative}`,
      // TODO: reuse / delete when colors are updated
      // iconColor: themeTokens.static.support.strong.negative,
      iconColor: themeTokens.static.text.sentiment.negative,
      // TODO: reuse / delete when colors are updated
      // iconBackgroundColor: themeTokens.static.support.standard.negative,
      iconBackgroundColor: red[20],
    },
    lowEmphasis: {
      color: themeTokens.static.text.sentiment.negative,
      backgroundColor: themeTokens.static.background.default.weak,
      border: `1.25px solid ${themeTokens.static.support.strong.negative}`,
      iconColor: themeTokens.static.support.strong.negative,
      iconBackgroundColor: themeTokens.static.support.weak.negative,
    },
  },
  info: {
    highEmphasis: {
      color: themeTokens.static.text.sentiment.info,
      backgroundColor: notificationsColorRange.backgroundColor.info,
      border: `1.25px solid ${themeTokens.static.support.weak.info}`,
      iconColor: themeTokens.static.support.strong.info,
      // TODO: reuse / delete when colors are updated
      // iconBackgroundColor: themeTokens.static.support.standard.info,
      iconBackgroundColor: blue[20],
    },
    lowEmphasis: {
      color: themeTokens.static.text.sentiment.info,
      backgroundColor: themeTokens.static.background.default.weak,
      border: `1.25px solid ${themeTokens.static.support.strong.info}`,
      iconColor: themeTokens.static.support.strong.info,
      iconBackgroundColor: themeTokens.static.support.weak.info,
    },
  },
  warning: {
    highEmphasis: {
      color: themeTokens.static.text.sentiment.notice,
      backgroundColor: notificationsColorRange.backgroundColor.warning,
      border: `1.25px solid ${themeTokens.static.text.divider.default.weak}`,
      iconColor: themeTokens.static.support.strong.notice,
      // TODO: reuse / delete when colors are updated
      // iconBackgroundColor: themeTokens.static.support.standard.notice,
      iconBackgroundColor: yellow[20],
    },
    lowEmphasis: {
      color: themeTokens.static.text.sentiment.notice,
      backgroundColor: themeTokens.static.background.default.weak,
      border: `1.25px solid ${themeTokens.static.support.strong.notice}`,
      iconColor: themeTokens.static.support.strong.notice,
      iconBackgroundColor: themeTokens.static.support.weak.notice,
    },
  },
});
