import { ExchangeLocalTickerType } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';

interface ExchangeLocalTickerProps {
  localTicker: ExchangeLocalTickerType;
  onEdit: (localTicker: ExchangeLocalTickerType) => void;
  onDelete: (_?: any) => void;
}

function ExchangeLocalTicker({ localTicker, onEdit, onDelete }: ExchangeLocalTickerProps) {
  return (
    <TableRow
      onClick={(event) => {
        onEdit(localTicker);
        event.stopPropagation();
      }}
    >
      <TableCell width="29%">{localTicker.name}</TableCell>
      <TableCell width="28%">{localTicker.currency}</TableCell>
      <TableCell width="29%">{localTicker.listingDate}</TableCell>
      <TableCell
        width="8%"
        onClick={(event: { stopPropagation: () => void }) => {
          event?.stopPropagation();
        }}
      >
        <TrashIcon type="button" onClick={onDelete} className="editIcons" />
      </TableCell>
    </TableRow>
  );
}

export default ExchangeLocalTicker;
