import Table from 'components/Table';
import { ProductType } from 'utils/types/product';
import { ORDER_IN_KIND } from 'utils/constants';
import { TableBody } from '@mui/material';
import Box from '@mui/material/Box';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import ExplorerLink from 'components/ExplorerLink';
import { Order } from 'utils/types/orders';

const SettlementInfoColumnsConfig = [
  {
    label: 'Primary Settlement Location AP',
    propName: 'primarySettlementLocationAP',
    propType: 'string',
    width: '60%',
    sort: false,
  },
  { label: '', propName: 'actions', propType: 'actions', width: '40%' },
];
const PrimarySettlementLocationIssuerColumnsConfig = [
  {
    label: 'Primary Settlement Location Issuer',
    propName: 'primarySettlementLocationIssuer',
    propType: 'string',
    width: '60%',
    sort: false,
  },
  { label: '', propName: 'actions', propType: 'actions', width: '40%' },
];
const TokensColumnsConfig = [
  {
    label: 'Destination Wallets',
    propName: 'destinationWallets',
    propType: 'string',
    width: '60%',
    sort: false,
  },
  {
    label: 'Wallet Address',
    propName: 'walletAddress',
    propType: 'actions',
    width: '40%',
    columnAlignment: 'right',
    sort: false,
  },
];

interface SettlementInformationProps {
  productType: ProductType;
  className?: string;
  order?: Order;
}

const SettlementInformation = ({ productType, className, order }: SettlementInformationProps) => {
  return (
    <Box className={className} component="section">
      {productType === 'ETP' && (
        <>
          <Table noPadding={true}>
            <TableHeaderWithMultiSort columns={SettlementInfoColumnsConfig} />
            <TableBody>
              <TableRow hoverEffects={false}>
                <TableCell>BP ID (SIX SIS AG)</TableCell>
                <TableCell align="right">{order?.settlement?.authorizedParticipantBPID}</TableCell>
              </TableRow>
              <TableRow hoverEffects={false}>
                <TableCell>Delivery/Receive</TableCell>
                <TableCell align="right">
                  {order?.deliveryType === ORDER_IN_KIND ? 'RECEIVE' : 'DELIVERY'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table noPadding={true}>
            <TableHeaderWithMultiSort columns={PrimarySettlementLocationIssuerColumnsConfig} />
            <TableBody>
              <TableRow hoverEffects={false}>
                <TableCell>BP ID (SIX SIS AG)</TableCell>
                <TableCell align="right">{process.env.REACT_APP_SETTLEMENT_BPID}</TableCell>
              </TableRow>
              <TableRow hoverEffects={false}>
                <TableCell>Delivery/Receive</TableCell>
                <TableCell align="right">
                  {order?.deliveryType === ORDER_IN_KIND ? 'DELIVERY' : 'RECEIVE'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
      {productType === 'Token' && (
        <>
          <Table noPadding={true}>
            <TableHeaderWithMultiSort
              columns={TokensColumnsConfig}
              columnAlignment={{ walletAddress: 'right' }}
            />
            <TableBody>
              <TableRow hoverEffects={false}>
                <TableCell>Copper</TableCell>
                <TableCell align="right">
                  {(order?.product?.destinationChain && order?.product?.contractAddress && (
                    <ExplorerLink
                      destinationChain={order?.product?.destinationChain}
                      data={order?.product?.contractAddress}
                      type="address"
                    />
                  )) ||
                    '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  );
};

export default SettlementInformation;
