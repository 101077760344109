import { TableCell } from 'components/Table/TableCell';
import { TableShowMoreCell } from 'components/Table/TableShowMoreCell';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { Contact } from 'utils/types/contacts';
import { TableRow } from 'components/Table/TableRow';

interface ExternalContactsTableRowProps {
  contact: Contact;
  editAction: (contact: Contact) => void;
  deleteAction: (contact: Contact) => void;
  showActionsCell?: boolean;
}

export const ExternalContactsTableRow = ({
  contact,
  editAction,
  deleteAction,
}: ExternalContactsTableRowProps) => {
  return (
    <TableRow key={contact.email} onClick={() => contact._actions?.update && editAction(contact)}>
      <TableCell>{contact.name}</TableCell>
      <TableCell>{contact.email}</TableCell>
      <TableShowMoreCell items={contact?.contactLists.map(({ name }) => name)} />
      <TableCell
        data-qa-id="editIcons"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {contact._actions?.update && (
          <TrashIcon
            data-qa-id="trashIcon"
            onClick={() => {
              deleteAction(contact);
            }}
            className="editIcons"
          />
        )}
      </TableCell>
    </TableRow>
  );
};
