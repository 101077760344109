/* eslint-disable complexity */
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import {
  CompanyCustodianWallet,
  ProductCustodianWallet,
  WalletUnionType,
} from 'utils/types/wallets';
import Tooltip from 'components/Tooltip';
import { StyledUnderlinedDiv, StyledFullWalletAddress } from './WalletsTable.styles';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

const THRESHOLD = 25;
const WALLET_ADDRESS_COLUMN_MAX_LENGTH = 30;

function condenseWalletAddress(
  inputString: string,
  threshold = THRESHOLD,
  maxLength = WALLET_ADDRESS_COLUMN_MAX_LENGTH
) {
  if (inputString.length < maxLength) {
    return inputString;
  }

  const regex = new RegExp(`[^\\s]{${threshold},}`);

  if (regex.test(inputString)) {
    const truncatedString = inputString.substring(0, 6) + '...' + inputString.slice(-4);
    return (
      <Tooltip
        title={
          <StyledFullWalletAddress sx={{ padding: 1 }} onClick={(event) => event.stopPropagation()}>
            {inputString}
          </StyledFullWalletAddress>
        }
        placement="top"
        bgColor="black"
      >
        <StyledUnderlinedDiv>{truncatedString}</StyledUnderlinedDiv>
      </Tooltip>
    );
  } else {
    return inputString;
  }
}

type Props = {
  wallet: WalletUnionType;
  editAction: () => void;
  deleteAction: () => void;
  activateAction: () => void;
  populateProductsRow?: boolean;
};

export const WalletsTableRow = ({
  wallet,
  editAction,
  deleteAction,
  activateAction,
  populateProductsRow,
}: Props) => {
  //* function to truncate wallet address. truncates address with 25+ consecutive charcters w/o whitespace. if total address length is 40 chars or less, returns it as is
  const revisedWalletAddress = condenseWalletAddress(wallet.address);

  return (
    <TableRow data-qa-id={`row-${wallet.description}`} key={wallet._id} onClick={editAction}>
      <TableCell data-qa-id={`wallet-address-${wallet.address}`}>{revisedWalletAddress}</TableCell>
      <TableCell data-qa-id={`wallet-chain-${wallet.chain}`}>{wallet.chain}</TableCell>
      <TableCell
        data-qa-id={`wallet-description-${wallet.description}`}
        colSpan={populateProductsRow ? 1 : 2}
      >
        {wallet.description}
      </TableCell>
      {populateProductsRow && (
        <TableCell>
          {(wallet as CompanyCustodianWallet | ProductCustodianWallet)?.productName ?? ''}
        </TableCell>
      )}
      <TableCell data-qa-id="actionBtn">
        {wallet._actions && Object.keys(wallet._actions) && (
          <ButtonDropdown id={wallet._id ?? ''} className="actionsCell">
            {wallet._actions?.update && (
              <ButtonDropdownItem id="editWallet" label="Edit wallet" onClick={editAction} />
            )}
            {wallet._actions?.activate && (
              <ButtonDropdownItem
                id="activateWallet"
                label="Activate wallet"
                onClick={activateAction}
              />
            )}
            {wallet._actions?.delete && (
              <ButtonDropdownItem
                id="deleteWallet"
                label="Deactivate wallet"
                onClick={deleteAction}
              />
            )}
          </ButtonDropdown>
        )}
      </TableCell>
    </TableRow>
  );
};
