import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { Contact, PartnerContact } from 'utils/types';
import { StyledIcon } from 'layouts/DashboardContainer/SideNavigationMenu.style';
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { useState } from 'react';
import { MuiStyledTableCellChevron } from 'shared/Tables/ContactsTable/ContactsTable.styles';
import { EditContactListTablePartnerRowDetails } from './EditContactListTablePartnerRowDetails';
import { TableCell } from 'components/Table/TableCell';
import { Checkbox, CheckboxProps } from 'components/BooleanInputs';
import Collapse from '@mui/material/Collapse';
import { TableRow } from 'components/Table/TableRow';

interface EditContactListTablePartnerRowProps {
  partner: PartnerContact;
  deleteAction?: (data: { contact?: Contact; partner?: PartnerContact }) => void;
  checkboxProps?: CheckboxProps;
}

export const EditContactListTablePartnerRow = ({
  partner,
  deleteAction,
  checkboxProps,
}: EditContactListTablePartnerRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <TableRow isExpanded={isExpanded}>
        {checkboxProps && (
          <TableCell data-qa-id={`${partner.name}-checkbox`}>
            <Checkbox {...checkboxProps} />
          </TableCell>
        )}
        <MuiStyledTableCellChevron
          onClick={() => setIsExpanded((prevState) => !prevState)}
          data-qa-id={`${partner.name}-dropdown`}
        >
          <StyledIcon>{isExpanded ? <ChevronUp /> : <ChevronDown />}</StyledIcon>
        </MuiStyledTableCellChevron>
        <TableCell style={{ width: '12%' }}>{partner.name}</TableCell>
        <TableCell></TableCell>
        <TableCell>{partner.contacts.length}</TableCell>
        <TableCell
          data-qa-id="editIcons"
          onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
            event.stopPropagation()
          }
        >
          <>
            {deleteAction && (
              <TrashIcon
                data-qa-id="trashIcon"
                onClick={(event) => {
                  deleteAction({ partner: partner });
                  event.stopPropagation();
                }}
                className="editIcons"
              />
            )}
          </>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ padding: 0 }}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <EditContactListTablePartnerRowDetails contacts={partner.contacts} isExpanded />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
