import { Order } from 'utils/types/orders';
import { ProductType } from 'utils/types/product';
import EmptyActualDeliverablesTable from './components/EmptyActualDeliverablesTable';
import ActualDeliverablesTable from './components/ActualDeliverablesTable';

export interface ActualDeliverablesProps {
  productType: ProductType;
  order?: Order;
}

const ActualDeliverables = ({ productType, order }: ActualDeliverablesProps) => {
  const allowedStatuses = order?.deliveryType === 'CASH' ? ['SETTLED', 'READY'] : ['SETTLED'];
  const showEmptyTable = productType === 'ETP' && !allowedStatuses.includes(order?.status || '');

  return (
    <>
      {showEmptyTable && <EmptyActualDeliverablesTable order={order} />}
      {!showEmptyTable && <ActualDeliverablesTable productType={productType} order={order} />}
    </>
  );
};

export default ActualDeliverables;
