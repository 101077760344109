/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Fragment } from 'react';
import { capitalize, groupBy } from 'lodash';
import {
  CUSTODIAN_INFO_STEP,
  ETPPartnerDetailsStepType,
  PARTNERS_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import { Button } from 'components/Button/Button';
import Table from 'components/Table';
import { ReactComponent as PencilIcon } from 'assets/pencil-alt.svg';
import { useUserPermissions } from 'store/user/selectors';
import useOnSubmit from 'pages/Instruments/hooks/useOnSubmit';
import { useCurrentInstrument, useCurrentInstrumentFormData } from 'store/instruments/selectors';
import { InstrumentFormDataProps } from 'pages/Instruments/Instruments.types';
import { mapPartners } from 'pages/Instruments/components/Form/PartnerDetails/components/ReviewStep/helpers';
import { StyledEntityName } from './ReviewStep.styles';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { TableRow } from 'components/Table/TableRow';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { ReviewStepColumnsConfig } from './ReviewStepColumnsConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { TableCell } from 'components/Table/TableCell';
import { StyledButtonRow } from 'pages/Tokens/components/Form/TokenDetails/components/SummaryStep/SummaryStep.styles';
import { StyledColumn } from 'pages/Tokens/components/Form/PartnerDetails/components/ReviewStep/ReviewStep.styles';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import { useCompaniesSuggestion } from 'utils/api/hooks/useCompaniesSuggestion.hook';

export interface ReviewStepProps {
  goBack: () => void;
  goToStep: (step: ETPPartnerDetailsStepType) => void;
}

const ProductReviewHeader = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        height: '64px',
        width: '100%',
        padding: theme.spacing(2),
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  );
};

function ReviewStep({ goBack, goToStep }: ReviewStepProps) {
  const { data: partners } = useCompaniesSuggestion();

  const permissions = useUserPermissions();
  const { instrument } = useCurrentInstrument();
  const instrumentFormsData = useCurrentInstrumentFormData() as InstrumentFormDataProps;
  const actions = instrument?._actions;
  const { onActivateInstrument, onSubmitForApproval, onCreateSeedOrder } = useOnSubmit(instrument);

  const wallets = [
    ...(instrumentFormsData[WALLETS_INFO_STEP]?.custodianWallets || []),
    ...(instrumentFormsData[WALLETS_INFO_STEP]?.unifiedWallets || []),
  ];

  return (
    <>
      <ProductReviewHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Partners" />
          <IconButton onClick={() => goToStep(PARTNERS_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean(instrumentFormsData)}
          noContentLabel="No data at the moment."
        >
          <TableRow>
            <TableCell>Issuer</TableCell>
            <TableCell>
              {
                partners?.find(
                  (partner) => partner._id === instrumentFormsData[PARTNERS_STEP]?.issuer
                )?.name
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Authorized Participants</TableCell>
            <TableCell>
              {mapPartners(
                instrumentFormsData[PARTNERS_STEP]?.authorizedParticipants,
                partners ?? []
              )?.map((name) => (
                <StyledEntityName key={name}>{name};</StyledEntityName>
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Calculation Agents</TableCell>
            <TableCell>
              {mapPartners(
                instrumentFormsData[PARTNERS_STEP]?.calculationAgents,
                partners ?? []
              )?.map((name) => (
                <StyledEntityName key={name}>{name};</StyledEntityName>
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>PCF Uploaders</TableCell>
            <TableCell>
              {mapPartners(instrumentFormsData[PARTNERS_STEP]?.pcfUploaders, partners ?? [])?.map(
                (name) => (
                  <StyledEntityName key={name}>{name};</StyledEntityName>
                )
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Extra Providers</TableCell>
            <TableCell>
              {instrumentFormsData[PARTNERS_STEP]?.custodians?.map(capitalize)?.map((name) => (
                <StyledEntityName key={name}>{name};</StyledEntityName>
              ))}
            </TableCell>
          </TableRow>
        </TableBodyWithStates>
      </Table>
      <ProductReviewHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Custodian Info" />
          <IconButton onClick={() => goToStep(CUSTODIAN_INFO_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean(instrumentFormsData[CUSTODIAN_INFO_STEP]?.length)}
          noContentLabel="No data at the moment."
        >
          <TableRow>
            <TableCell>Custodians Accounts</TableCell>
            <TableCell>
              {instrumentFormsData[CUSTODIAN_INFO_STEP]?.map((custodian) => (
                <StyledEntityName key={custodian._id}>{custodian.name};</StyledEntityName>
              ))}
            </TableCell>
          </TableRow>
        </TableBodyWithStates>
      </Table>
      <ProductReviewHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Wallets Info" />
          <IconButton onClick={() => goToStep(WALLETS_INFO_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>
      {Object.entries(groupBy(wallets, 'chain')).map(([chain, walletsInfo], index) => (
        <Fragment key={chain}>
          <Stack height="54px" display="flex" justifyContent="center" width="100%" padding={2}>
            <Typography variant="labelLargeStrong">{chain} Wallets</Typography>
          </Stack>
          <Table>
            <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
            <TableBodyWithStates
              hasContent={Boolean(walletsInfo.length)}
              noContentLabel="No data at the moment."
            >
              <TableRow>
                <TableCell>Wallet</TableCell>
                <TableCell>
                  {walletsInfo.map((wallet) => (
                    <StyledEntityName key={wallet._id}>{wallet?.description};</StyledEntityName>
                  ))}
                </TableCell>
              </TableRow>
            </TableBodyWithStates>
          </Table>
        </Fragment>
      ))}
      <StyledButtonRow>
        <StyledColumn cols={2}>
          <Button fullWidth type="button" variant="secondary" onClick={goBack}>
            Back
          </Button>
        </StyledColumn>
        {Boolean(permissions?.canEditEtp && actions?.submitForApproval) && (
          <StyledColumn cols={2}>
            <Button fullWidth type="button" variant="primary" onClick={onSubmitForApproval}>
              Ready for Seed
            </Button>
          </StyledColumn>
        )}
        {Boolean(
          permissions?.canApproveEtp && actions?.activate && !actions?.submitForApproval
        ) && (
          <>
            <StyledColumn cols={2}>
              <Button fullWidth type="button" variant="primary" onClick={onCreateSeedOrder}>
                Create Seed Order
              </Button>
            </StyledColumn>
            <StyledColumn cols={2}>
              <Button fullWidth type="button" variant="primary" onClick={onActivateInstrument}>
                Activate
              </Button>
            </StyledColumn>
          </>
        )}
      </StyledButtonRow>
    </>
  );
}

export default ReviewStep;
