import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { PCFDetailsListColumnConfig } from 'pages/Ledger/PCF/PCFDetails/PCFDetailsAP/tableColumnsConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table from 'components/Table';
import { PortfolioComposition } from 'utils/types/pcfs';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import { Box, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';

export interface PCFBenchmarkInformationProps {
  pcf?: PortfolioComposition;
  isLoading?: boolean;
}

function PCFBenchmarkInformation({ pcf, isLoading }: PCFBenchmarkInformationProps) {
  return (
    <Card
      header={
        <Box padding={'16px'}>
          <Typography variant="subheadingMedium" sx={{ wordBreak: 'break-word' }}>
            Benchmark Information
          </Typography>
        </Box>
      }
      headerDivider={false}
      body={
        <Table noPadding>
          <TableHeaderWithMultiSort columns={PCFDetailsListColumnConfig} />
          <TableBodyWithStates
            loadingData={isLoading}
            hasContent={!!(pcf?.product?.underling?.name && pcf?.product?.underling?.isin)}
            noContentLabel="No PCF data."
          >
            <PCFCompositionRow pcf={pcf} />
          </TableBodyWithStates>
        </Table>
      }
    />
  );
}

export default PCFBenchmarkInformation;

const PCFCompositionRow = ({ pcf }: { pcf?: PortfolioComposition }) => {
  return (
    <>
      <TableRow>
        <TableCell>Benchmark</TableCell>
        <TableCell>{pcf?.product?.underling?.name || '-'}</TableCell>
        <TableCell>{pcf?.official?.product?.underling?.name || '-'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Benchmark ISIN</TableCell>
        <TableCell>{pcf?.product?.underling?.isin || '-'}</TableCell>
        <TableCell>{pcf?.official?.product?.underling?.isin || '-'}</TableCell>
      </TableRow>
    </>
  );
};
