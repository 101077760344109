import ExchangeLocalTicker from 'pages/Instruments/components/Form/EtpDetails/components/ExchangeStep/components/ExchangeLocalTicker';
import {
  ExchangeLocalTickerType,
  ExchangeType,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import Table from 'components/Table';
import { TableHead as MuiTableHead, TableBody } from '@mui/material';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';

interface ExchangeLocalTickerProps {
  productExchange: ExchangeType;
  localTickers?: ExchangeLocalTickerType[];
  onEdit: (localTicker: ExchangeLocalTickerType) => void;
  onDelete: (localTickerIndex: number) => void;
}

function ExchangeLocalTickerList({
  productExchange,
  localTickers = [],
  onEdit,
  onDelete,
}: ExchangeLocalTickerProps) {
  return (
    <TableRow hoverEffects={false}>
      <TableCell colSpan={5}>
        <Table noPadding={true}>
          <MuiTableHead>
            <TableRow hoverEffects={false}>
              <TableCell width="44%">Local ticker</TableCell>
              <TableCell width="42%">Currency</TableCell>
              <TableCell width="42%">Listing Date</TableCell>
              <TableCell width="8%"></TableCell>
            </TableRow>
          </MuiTableHead>
          <TableBody>
            {localTickers.map((localTicker, index) => (
              <ExchangeLocalTicker
                key={`${productExchange.name}-${localTicker.name}-${localTicker.currency}`}
                localTicker={localTicker}
                onEdit={onEdit}
                onDelete={() => onDelete(index)}
              />
            ))}
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  );
}

export default ExchangeLocalTickerList;
