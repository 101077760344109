import { SecuritiesRegister } from 'pages/Orders/Register/types';
import Table from 'components/Table';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { renderOrderValue } from 'shared/Tables/table.utils';
import { formatAddress } from 'utils/formatting';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';

export interface RegisterProductInformationProps {
  register?: SecuritiesRegister;
}

export const ProductInformation = ({ register }: RegisterProductInformationProps) => {
  return (
    <Box component="section">
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Product Information</Typography>
      </MuiStyledSectionHeader>
      <Table>
        <TableBodyWithStates hasContent={!!register}>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell align="right">
              {formatAddress([
                register?.issuer.address.addressLineOne,
                register?.issuer.address.addressLineTwo,
                register?.issuer.address.city?.name,
                register?.issuer.address.country?.name,
                register?.issuer.address.postalCode,
              ])}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell align="right">
              {renderOrderValue(Boolean(register?.name), register?.name)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ISIN</TableCell>
            <TableCell align="right">
              {renderOrderValue(Boolean(register?.isin), register?.isin)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Currency</TableCell>
            <TableCell align="right">
              {renderOrderValue(Boolean(register?.currency), register?.currency)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Maturity</TableCell>
            <TableCell align="right">
              {renderOrderValue(Boolean(register?.maturity), register?.maturity)}
            </TableCell>
          </TableRow>
        </TableBodyWithStates>
      </Table>
    </Box>
  );
};

export default ProductInformation;
