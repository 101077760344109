import { ParamsType } from 'hooks/useUrlParams';
import SingleDatePicker from 'components/DatepickerSingleDate/index';
import { parseISO, format } from 'date-fns';
import { NavStatusBarInfo } from 'utils/types/nav';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { ProductSelectFilter } from 'pages/Ledger/PCF/components/PCFStyles';
import { CounterCard } from 'shared/CounterCard';
import { Box } from '@mui/material';

interface NavReviewInforBarProps {
  setQueryParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  queryParams: ParamsType;
  overviewBarInfoData: NavStatusBarInfo | null;
}

export const NavReviewInforBar = ({
  queryParams,
  setQueryParams,
  overviewBarInfoData,
}: NavReviewInforBarProps) => {
  const date =
    queryParams.valuationDate && typeof queryParams.valuationDate === 'string'
      ? parseISO(queryParams.valuationDate)
      : null;

  const inputDateFormat = 'dd/MM/yyyy';

  return (
    <Box display="flex" gap={2} alignItems="center">
      <CounterCard number={overviewBarInfoData?.total} cardColor={'default'} label={'Total NAVs'} />
      <CounterCard
        number={overviewBarInfoData?.totalApproved}
        cardColor={'success'}
        label={'Approved'}
      />
      <CounterCard
        number={overviewBarInfoData?.totalWithError}
        cardColor={'error'}
        label={'Discrepancies'}
      />

      <ProductSelectFilter
        placeholder="All instruments"
        clearable
        onChange={(product) =>
          setQueryParams((prevState) => ({ ...prevState, productId: product ?? '' }))
        }
        value={String(queryParams.productId)}
        size={'medium'}
      />
      <SingleDatePicker
        date={date}
        onChange={(date) =>
          setQueryParams((prevState) => ({
            ...prevState,
            valuationDate: format(date || new Date(), DEFAULT_DATE_FORMAT),
          }))
        }
        maxDate={new Date()}
        inputDateFormat={inputDateFormat}
        selectSize="small"
      />
    </Box>
  );
};
