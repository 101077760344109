/* eslint-disable complexity */
import BigNumber from 'bignumber.js';
import PageTitle from 'components/PageTitle';
import ProductIcon from 'components/ProductIcon';
import { IndexWeigthsTable } from './tables/IndexWeigthsTable/IndexWeigthsTable';
import { RebalanceDetailsTable } from './tables/RebalanceDetailsTable/RebalanceDetailsTable';
import { RebalancesActionBar } from 'pages/Rebalance/components/RebalancesActionBar/RebalancesActionBar';
import { StyledRebalanceDetailsContainer } from 'pages/Rebalance/RebalancePage.styles';
import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useCurrentRebalanceQuery } from 'pages/Rebalance/hooks/useCurrentRebalance';
import {
  useApproveRebalanceDetails,
  useCalculateRebalanceDetails,
} from 'pages/Rebalance/hooks/useCalculateRebalanceDetails';
import {
  RebalanceConstituent,
  RebalanceDetails,
  RebalanceWithDetails,
} from 'utils/types/rebalance';
import { findIndex } from 'lodash';
import { compileUrlToRebalanceDetails, downloadXLS } from 'pages/Rebalance/utils/utils';
import { DateTime } from 'utils/datetime';
import useAppModal from 'hooks/useAppModal';
import { MODAL_ACTIONS, REBALANCE_MODAL } from 'shared/Modals/constants';
import { RebalanceNotification } from './components/RebalanceNotification/RebalanceNotification';
import { ActionButtonWithTooltipProps } from 'components/Button/variants/ActionButtonWithTooltip';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { Card } from 'components/Card/Card';
import Tag from 'components/Tag/Tag';

function calculateConstituents(
  rebalanceConstituents: RebalanceConstituent[],
  rebalanceDetails: RebalanceDetails
): RebalanceConstituent[] {
  const index = findIndex(rebalanceConstituents, ['ticker', rebalanceDetails.ticker]);
  if (index === -1) {
    return rebalanceConstituents;
  }

  const rebalanceConstituent = {
    ...rebalanceConstituents[index],
    fees: {
      buyFeesBps: new BigNumber(rebalanceDetails.buyFeesBps).toNumber(),
      ...(rebalanceDetails.sellFeesBps
        ? { sellFeesBps: new BigNumber(rebalanceDetails.sellFeesBps).toNumber() }
        : {}),
    },
    price: rebalanceDetails.price,
    targetWeight: rebalanceDetails.targetWeight,
  };

  return [
    ...rebalanceConstituents.slice(0, index),
    rebalanceConstituent,
    ...rebalanceConstituents.slice(index + 1),
  ];
}

export const RebalanceDetailsPage = () => {
  const openModal = useAppModal();
  const navigate = useNavigate();
  const { productId, date } = useParams();

  const { data: rebalance, isLoading, refetch } = useCurrentRebalanceQuery(productId, date);

  const approveRebalanceDetails = useApproveRebalanceDetails(() => refetch());

  const { calculateRebalanceMutation, calculatedRebalance } = useCalculateRebalanceDetails();

  const isRebalanceApprovedOrSkipped = rebalance?.approvedAt || rebalance?.skipAt;
  const isCalculating = calculateRebalanceMutation.isLoading;

  const canBeApproved = calculatedRebalance
    ? calculatedRebalance?._actions?.approve
    : rebalance?._actions.approve;

  const calculateDetails = (updatedRebalanceDetails: RebalanceDetails) => {
    if (rebalance?._id && !calculateRebalanceMutation.isLoading) {
      calculateRebalanceMutation.mutate({
        id: rebalance._id,
        rebalanceConstituents: calculateConstituents(
          calculateRebalanceMutation.data?.constituents ?? rebalance.constituents ?? [],
          updatedRebalanceDetails
        ),
      });
    }
  };

  const approveRebalance = useCallback(() => {
    if (!rebalance || approveRebalanceDetails.isLoading || calculateRebalanceMutation.isLoading)
      return;

    const rebalanceDetails = calculatedRebalance?.details ?? rebalance.details ?? [];

    const calculatedConstituents = rebalance.constituents.map((constituent) => {
      const details = rebalanceDetails.find((details) => details.ticker === constituent.ticker);
      const buyFeesBps = new BigNumber(
        details?.buyFeesBps || constituent.fees.buyFeesBps
      ).toNumber();
      const sellFees = details?.sellFeesBps ?? constituent.fees.sellFeesBps;
      const sellFeesBps = sellFees && new BigNumber(sellFees).toNumber();

      return {
        ...constituent,
        fees: {
          buyFeesBps,
          ...(sellFeesBps ? { sellFeesBps } : {}),
        },
        price: details?.price ?? constituent.price,
        targetWeight: details?.targetWeight ?? constituent.targetWeight,
      };
    });

    approveRebalanceDetails.mutate({
      id: rebalance._id,
      rebalanceConstituents: calculatedConstituents,
    });
  }, [
    approveRebalanceDetails,
    calculateRebalanceMutation.isLoading,
    calculatedRebalance?.details,
    rebalance,
  ]);

  const skipRebalance = (rebalance: RebalanceWithDetails | undefined) => {
    openModal(
      {
        modalName: REBALANCE_MODAL,
        modalData: {
          data: rebalance,
          type: MODAL_ACTIONS.CUSTOM,
        },
      },
      {
        onCloseModalAction: () => refetch(),
      }
    );
  };

  const approveButton = useMemo(
    (): ActionButtonWithTooltipProps | null =>
      !isRebalanceApprovedOrSkipped && canBeApproved
        ? {
            onClick: approveRebalance,
            label: 'Approve Trades',
            disabled: approveRebalanceDetails.isLoading || Boolean(rebalance?.approvedAt),
            isLoading: approveRebalanceDetails.isLoading,
            enableTooltip: true,
            tooltipText:
              'Rebalancing locked until final prices are available on the day of rebalancing',
            size: 'large',
          }
        : null,
    [
      isRebalanceApprovedOrSkipped,
      calculatedRebalance?._actions.approve,
      rebalance?._actions.approve,
      rebalance?.approvedAt,
      approveRebalance,
      approveRebalanceDetails.isLoading,
    ]
  );

  return (
    <>
      <TitleArea
        title={
          <StyledTableCellContentWithIcon>
            {rebalance && (
              <ProductIcon
                className="product-icon"
                iconUrl={rebalance?.product.fundIcon}
                ticker={rebalance?.product.ticker}
                size="40px"
              />
            )}
            <PageTitle>{rebalance?.product.ticker}</PageTitle>
            {rebalance?.skipAt && <Tag label={'Skipped'} variant="green" />}
            {rebalance?.approvedAt && !rebalance?.skipAt && (
              <Tag label={'Approved'} variant="green" />
            )}
          </StyledTableCellContentWithIcon>
        }
        showBackButton={true}
      />
      <RebalanceNotification rebalance={rebalance} />
      <Card
        header={
          <RebalancesActionBar
            date={String(date)}
            onDateChange={(date) =>
              navigate(compileUrlToRebalanceDetails(productId, new DateTime(date).toDateString()))
            }
            xlsButton={{ onClick: () => downloadXLS(rebalance), label: 'Download (XLS)' }}
            approveButton={approveButton}
            skipRebalanceButton={{
              onClick: () => rebalance?._actions?.skip?.uri && skipRebalance(rebalance),
              label: 'Skip rebalance',
              disabled: !rebalance?._actions.skip,
            }}
            rebalance={rebalance}
            showDateFilter={true}
          />
        }
        body={
          <>
            <StyledRebalanceDetailsContainer>
              <IndexWeigthsTable
                rebalance={calculatedRebalance ?? rebalance}
                isLoading={isLoading}
              />
              <RebalanceDetailsTable
                rebalance={calculatedRebalance ?? rebalance}
                initialRebalance={rebalance}
                isLoading={isLoading}
                editRowData={calculateDetails}
                isCalculating={isCalculating}
              />
            </StyledRebalanceDetailsContainer>
          </>
        }
      />
    </>
  );
};
