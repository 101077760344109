import { styled as MuiStyled, TableRow as MuiTableRow, TableCell } from '@mui/material';
import Tooltip from 'components/Tooltip';
import TooltipContent from 'components/Tooltip/TooltipContent';
import { CSSProperties, ReactNode, useState } from 'react';

type TableRowProps = {
  breakWords?: boolean;
  hoverEffects?: boolean;
  isExpanded?: boolean;
  isError?: 'true' | 'false';
  withActionsTableCell?: boolean;
  size?: 'medium' | 'large';
  onClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  tooltipDescription?: string;
  style?: CSSProperties;
  sx?: object;
  background?: 'default' | 'header' | 'warning';
  borderRadiusTop?: Boolean; // border radius for first & last table cells for variants other than default
  borderRadiusBottom?: Boolean; // border radius for first & last table cells for variants other than default
};

const StyledTableRow = MuiStyled(MuiTableRow)<TableRowProps>(
  ({
    theme,
    isError = false,
    isExpanded,
    withActionsTableCell,
    breakWords,
    hoverEffects = true,
    size = 'medium',
    background = 'default',
    borderRadiusTop = true,
    borderRadiusBottom = true,
  }) => ({
    position: 'relative',
    height: size === 'medium' ? '40px' : '48px',
    ...(isError && {
      backgroundColor: theme.palette.error.lighter,
    }),
    ...(isExpanded && {
      backgroundColor: theme.palette.grey[200],
    }),
    ...(withActionsTableCell && {
      '&:hover': {
        backgroundColor: theme.palette.overlay.secondary.hover,
        '.editIcons': {
          display: 'inline-grid',
        },
        '.actionsCell': {
          visibility: 'unset',
        },
      },
    }),
    ...(breakWords && {
      wordBreak: 'break-all',
    }),
    ...(hoverEffects && {
      '&:hover': {
        backgroundColor: theme.palette.overlay.secondary.hover,
        '.editIcons': {
          display: 'inline-grid',
        },
        '.actionsCell': {
          visibility: 'unset',
        },
        cursor: 'pointer',
      },
    }),
    ...((background === 'header' || background === 'warning') && {
      backgroundColor:
        background === 'header' ? theme.palette.customBackground.default.standard : '#FFF8F3', // @TODO useTheme orange[10]
      '& .MuiTableCell-root': {
        border: 'none',
      },
      '& .MuiTableCell-root:first-of-type': {
        borderTopLeftRadius: borderRadiusTop ? '8px' : '0px',
        borderBottomLeftRadius: borderRadiusBottom ? '8px' : '0px',
      },
      '& .MuiTableCell-root:last-of-type': {
        borderTopRightRadius: borderRadiusTop ? '8px' : '0px',
        borderBottomRightRadius: borderRadiusBottom ? '8px' : '0px',
      },
    }),
  })
);

export const TableRow = ({
  tooltipDescription,
  children,
  ...props
}: TableRowProps & { children: ReactNode }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledTableRow
      {...props}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {tooltipDescription && (
        <TableCell style={{ width: 0, padding: 0 }}>
          {/* Position tooltip in center of the row */}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              pointerEvents: 'none',
            }}
          >
            <Tooltip
              title={<TooltipContent description={tooltipDescription} />}
              placement="top"
              arrow
              open={isHovered}
            >
              <span></span>
            </Tooltip>
          </div>
        </TableCell>
      )}
    </StyledTableRow>
  );
};

export default TableRow;
