import {
  CUSTODIAN_INFO_STEP,
  PARTNERS_STEP,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.steps';
import {
  CustodiansListProps,
  PartnerDetailsFormProps,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.types';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import isEmpty from 'lodash/isEmpty';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppModal from 'hooks/useAppModal';
import { CustodianStatus } from 'utils/constants/custodians';
import { TokenWalletsTableRow } from './TokenWalletsTableRow';
import { TokensWalletsTableColumnsConfig } from './tableColumnsConfig';
import { MODAL_ACTIONS, WALLET_MODAL } from 'shared/Modals/constants';
import { CompanySuggestionDto } from 'utils/types';
import { ProductCustodianWallet, WalletType } from 'utils/types/wallets';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import Button from 'components/Button';
import { WALLETS_TABS_STATUS } from 'utils/constants/wallets';
import { WalletsInfoStepTabs } from 'pages/Tokens/components/Form/PartnerDetails/components/WalletsInfoStep/components/WalletsInfoStepTabs';
import { createNotification } from 'store/notifications/actions';
import { infoNotification } from 'shared/Notifications/general.notifications';
import { CONSTITUENT_STEP } from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import { ConstituentAssetType } from 'utils/types/product';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import Box from '@mui/material/Box';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';

interface WalletsTableProps {
  tokensFormsData: PartnerDetailsFormProps;
  loading: boolean;
  onUpdate: () => void;
  partners: CompanySuggestionDto[];
  onChangeTab: (data: WALLETS_TABS_STATUS) => void;
  activeTab: WALLETS_TABS_STATUS;
  wallets: ProductCustodianWallet[];
  custodians: CustodiansListProps;
}

function TokensWalletsTable({
  loading,
  onUpdate,
  partners,
  tokensFormsData,
  onChangeTab,
  activeTab,
  wallets,
  custodians,
}: WalletsTableProps) {
  const dispatch = useAppDispatch();
  const openModal = useAppModal();
  const handleChangeTab = (tab: WALLETS_TABS_STATUS) => {
    onChangeTab(tab);
  };
  const openWalletModal = (
    type: MODAL_ACTIONS,
    walletType: WalletType,
    wallet?: ProductCustodianWallet
  ) => {
    if (!custodians || custodians?.length === 0) {
      dispatch(
        createNotification(
          infoNotification("You can't add wallet if custodians or constituents are empty")
        )
      );
    } else {
      openModal(
        {
          modalName: WALLET_MODAL,
          modalData: {
            data: {
              wallet,
              type: walletType,
              productData: {
                authorizedParticiants: tokensFormsData[PARTNERS_STEP]?.authorizedParticipants ?? [],
                partners: partners,
                custodians: custodians?.filter((custodian) => {
                  return custodian.status === CustodianStatus.ACTIVE;
                }),
                constituents: tokensFormsData[CONSTITUENT_STEP] as ConstituentAssetType[],
              },
            },
            companyData: {
              id: (wallet as ProductCustodianWallet)?.transactingCompany ?? '',
              name:
                partners.find(
                  (partner) =>
                    partner._id === (wallet as ProductCustodianWallet)?.transactingCompany
                )?.name ?? '',
            },
            custom: {
              isToken: true,
            },
            type: type,
          },
        },
        {
          onCloseModalAction: () => onUpdate(),
        }
      );
    }
  };

  const openDeleteWalletModal = (walletType: WalletType, wallet?: ProductCustodianWallet) => {
    openModal(
      {
        modalName: WALLET_MODAL,
        modalData: {
          data: {
            wallet,
            type: walletType,
          },
          companyData: {
            id: (wallet as ProductCustodianWallet)?.transactingCompany,
            name:
              partners.find(
                (partner) => partner._id === (wallet as ProductCustodianWallet)?.transactingCompany
              )?.name ?? '',
          },
          type: MODAL_ACTIONS.DELETE,
        },
      },
      {
        onCloseModalAction: () => onUpdate(),
      }
    );
  };

  return (
    <>
      <TableTitleWithActions
        title="Wallets"
        actions={[
          <Button
            data-qa-id="addWalletButton"
            variant="secondary"
            onClick={() => openWalletModal(MODAL_ACTIONS.CREATE, WalletType.CUSTODIAN_WALLET)}
            type="button"
            size="medium"
          >
            <span>Add Wallet</span>
            <PlusIcon />
          </Button>,
        ]}
      />
      <Table
        tabsComponent={
          <Box paddingLeft={2}>
            <WalletsInfoStepTabs
              active={activeTab}
              onChangeTab={handleChangeTab}
            ></WalletsInfoStepTabs>
          </Box>
        }
        noPadding
      >
        <TableHeaderWithMultiSort
          showActionsCell={true}
          columns={TokensWalletsTableColumnsConfig}
        />
        <TableBodyWithStates
          loadingData={loading}
          hasContent={!isEmpty(wallets)}
          noContentLabel="No connected wallets."
        >
          {wallets?.map((wallet) => (
            <TokenWalletsTableRow
              key={wallet._id}
              wallet={wallet as ProductCustodianWallet}
              editAction={() => {
                openWalletModal(MODAL_ACTIONS.EDIT, WalletType.CUSTODIAN_WALLET, wallet);
              }}
              deleteAction={() => openDeleteWalletModal(WalletType.CUSTODIAN_WALLET, wallet)}
              activateAction={() => {
                openWalletModal(MODAL_ACTIONS.CUSTOM, WalletType.CUSTODIAN_WALLET, wallet);
              }}
              custodianName={
                tokensFormsData[CUSTODIAN_INFO_STEP]?.find(
                  (custodian) =>
                    custodian._id === (wallet as ProductCustodianWallet).custodianAccount
                )?.name ?? ''
              }
              partners={partners}
            />
          ))}
        </TableBodyWithStates>
      </Table>
    </>
  );
}

export default TokensWalletsTable;
