import { Box, styled as MuiStyled, Typography } from '@mui/material';
import ProductSelector from 'components/ProductSelector';
import { StyledCardFooter } from 'components/Card/Card.styles';
import { TableCell } from 'components/Table';

export const StyledInfoBarWrapper = MuiStyled(Box)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const StyledInfoBarWrapperCounters = MuiStyled(Box)`
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  display: flex;
  height: inherit;
`;

export const ProductSelectFilter = MuiStyled(ProductSelector)`
  border: none;
  min-width: 128px;
  max-width: 256px;
  button {
    outline: 0;
  }
`;

export const StyledDetailsContainer = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacers['spacing-16']};
`;

export const StyledToolbar = MuiStyled(Box)`
  width: 100%;
  min-height: 56px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  background: white;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

export const StyledDatePickerContainer = MuiStyled(Box)`
  display: flex;
  position: relative;
  width: 240px;

  & button {
    position: absolute;
    right: 0;
    z-index: 1;
  }
`;

export const StyledPCFActionsContainer = MuiStyled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacers['spacing-08']};
`;

// @TODO do not use in multiple components
export const StyledUploadFooter = MuiStyled(StyledCardFooter)`
  justify-content: flex-end;
`;

export const StyledTableCell = MuiStyled(TableCell)`
  width: 33%;
`;

export const StyledFxRate = MuiStyled('span')`
  color: #808080;
  padding-left: ${({ theme }) => theme.spacers['spacing-04']};
`;

export const MuiStyledFxRate = MuiStyled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  padding: theme.spacers['spacing-04'],
}));
