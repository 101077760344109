import Box from '@mui/material/Box';
import Input from 'components/Input';
import { ReactComponent as UnlockIcon } from 'assets/unlock.svg';
import { ReactComponent as LockIcon } from 'assets/lock.svg';
import Button from 'components/Button';
import { useState } from 'react';
import BigNumber from 'bignumber.js';
import { CustomBasketDeliverable } from 'utils/types/orders';

interface DeliverableWeightProps {
  weight?: string;
  isLocked?: boolean;
  onChangeDeliverable?: (
    deliverable: Pick<CustomBasketDeliverable, 'isLocked' | 'ticker' | 'weight'>
  ) => void;
  ticker: string;
}

const DeliverableWeight = ({
  isLocked,
  weight = '0',
  ticker,
  onChangeDeliverable,
}: DeliverableWeightProps) => {
  const handleToggleLocker = () =>
    onChangeDeliverable &&
    onChangeDeliverable?.({
      isLocked: !isLocked,
      weight:
        new BigNumber(weight).multipliedBy(100).toFixed(2, BigNumber.ROUND_HALF_UP) === value
          ? weight
          : value,
      ticker,
    });
  const [value, setValue] = useState(
    new BigNumber(weight).multipliedBy(100).toFixed(2, BigNumber.ROUND_HALF_UP)
  );

  return (
    <Box sx={{ position: 'relative', width: '120px' }}>
      <Input
        disabled={isLocked}
        onBlur={() => {
          onChangeDeliverable?.({
            isLocked: true,
            weight: new BigNumber(value).dividedBy(100).toFixed(4, BigNumber.ROUND_HALF_UP),
            ticker,
          });
        }}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        size="small"
        type="text"
        value={value}
      />
      <Box
        sx={{
          position: 'absolute',
          right: '16px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        <Button
          variant="ghost"
          style={{ padding: '0', background: 'none' }}
          onClick={handleToggleLocker}
        >
          {isLocked ? <LockIcon /> : <UnlockIcon />}
        </Button>
      </Box>
    </Box>
  );
};

export default DeliverableWeight;
