import ExchangeTable from 'shared/Tables/ExchangeTable/ExchangeTable';
import { useLoadExchanges } from 'pages/Admin/ExchangesPage/hooks/useExchanges.hook';
import { useUrlParams } from 'hooks/useUrlParams';
import { privateRoutesUrls } from 'router/constants';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { EXCHANGE_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { Exchange } from 'utils/types';
import useAppModal from 'hooks/useAppModal';
import Button from 'components/Button';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { Card } from 'components/Card/Card';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';

const ExchangesPage = () => {
  const openModal = useAppModal();
  const { urlParams, setUrlParams } = useUrlParams(
    { page: DEFAULT_PAGE, pageSize: DEFAULT_ITEMS_PER_PAGE },
    privateRoutesUrls.dashboardRoutes.adminExchanges
  );
  const {
    data: exchanges,
    isLoading,
    refetch,
    pagination: paginationResponse,
  } = useLoadExchanges(urlParams);
  const pagination = useBackendPagination(paginationResponse, setUrlParams);

  const openExchangeModal = (actionType: MODAL_ACTIONS, exchange: Exchange | null) => {
    openModal(
      {
        modalName: EXCHANGE_MODAL,
        modalData: { data: exchange, type: actionType },
      },
      {
        onCloseModalAction: () => refetch(),
      }
    );
  };

  const createExchangeButton = (
    <Button
      onClick={() => openExchangeModal(MODAL_ACTIONS.CREATE, null)}
      size="medium"
      type="button"
      data-qa-id="createExchangeButton"
    >
      <span>Create Exchange</span>
      <PlusIcon />
    </Button>
  );

  return (
    <>
      <TitleArea title={'Exchanges'} actionButton={createExchangeButton} />
      <Card
        header={<TableTitleWithActions title="Active Exchanges" />}
        headerDivider={false}
        body={
          <ExchangeTable
            urlParams={urlParams}
            setUrlParams={setUrlParams}
            exchanges={exchanges}
            loadingData={isLoading}
            deleteAction={(exchange) => openExchangeModal(MODAL_ACTIONS.DELETE, exchange)}
            editAction={(exchange) => openExchangeModal(MODAL_ACTIONS.EDIT, exchange)}
            pagination={pagination}
          />
        }
      />
    </>
  );
};

export default ExchangesPage;
