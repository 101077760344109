import GenericTabs from 'shared/GenericTabs';
import UsersTable from 'shared/Tables/UsersTable/UsersTable';
import useAppModal from 'hooks/useAppModal';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { ParamsType } from 'hooks/useUrlParams';
import { PartnersUser } from 'utils/types';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import Button from 'components/Button';
import { USERS_STATUS_LABEL, USER_TAB_STATUS } from 'utils/constants/users';
import { USER_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { getUsersPerCompanyQuery } from 'utils/constants/reactQueries';
import { queryClient } from 'utils/api/queries/queryClient';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useCompanyQuery } from 'pages/Partners/hooks/useCompanyQuery';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useUserPermissions } from 'store/user/selectors';
import { useUsersPerCompanyQuery } from 'pages/Partners/hooks/useUsersPerCompany';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import { Box } from '@mui/material';

const urlParamsDefaults = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_ITEMS_PER_PAGE,
  tab: USER_TAB_STATUS.ACTIVE,
};

export const UsersStep = () => {
  const openModal = useAppModal();
  const permissions = useUserPermissions();
  const { id: partnerId } = useParams();
  const { data: partnerData } = useCompanyQuery(partnerId ?? '');
  const companyID = partnerData?._id ?? '';
  const canAddUser =
    (permissions?.canInviteExternalCompanyUsers || permissions?.canInviteCompanyUsers) &&
    partnerData?._actions?.addUser;
  const [urlParams, setUrlParams] = useState<ParamsType>(urlParamsDefaults);
  const { data, isLoading: loading } = useUsersPerCompanyQuery(companyID, urlParams);
  const paginationResponse = { page: data?.page, pageSize: data?.pageSize, total: data?.total };
  const users = data?.data;

  const pagination = useBackendPagination(paginationResponse, setUrlParams);

  const openUserModal = (type: MODAL_ACTIONS, user?: PartnersUser, reset2FA?: boolean) => {
    openModal(
      {
        modalName: USER_MODAL,
        modalData: {
          data: user,
          companyData: {
            id: companyID,
            name: partnerData?.name,
          },
          type: type,
          custom: {
            reset2FA: Boolean(reset2FA),
          },
        },
      },
      {
        onCloseModalAction: () =>
          queryClient.invalidateQueries({ queryKey: [getUsersPerCompanyQuery] }),
      }
    );
  };

  const tableToolbarTopButton = canAddUser && (
    <Button variant="secondary" size="medium" onClick={() => openUserModal(MODAL_ACTIONS.CREATE)}>
      <span>Add User</span>
      <PlusIcon />
    </Button>
  );

  return (
    <>
      <Box paddingLeft={2} paddingRight={2}>
        <TableTitleWithActions title="Users" actions={[tableToolbarTopButton]} />
        <GenericTabs
          tabs={[
            [USERS_STATUS_LABEL.ACTIVE, USER_TAB_STATUS.ACTIVE],
            [USERS_STATUS_LABEL.PENDING, USER_TAB_STATUS.INVITED],
          ]}
          setUrlParamsWithDefaults={(urlParams) =>
            setUrlParams({ ...urlParamsDefaults, ...urlParams })
          }
          active={urlParams.tab as string}
        ></GenericTabs>
      </Box>

      <UsersTable
        urlParams={urlParams}
        setUrlParams={setUrlParams}
        tableProperties={{
          pagination,
        }}
        deleteAction={(user) => openUserModal(MODAL_ACTIONS.DELETE, user)}
        editAction={(user) => openUserModal(MODAL_ACTIONS.EDIT, user)}
        reset2FA={(user) => openUserModal(MODAL_ACTIONS.CUSTOM, user, true)}
        resendInvitationAction={(user) => openUserModal(MODAL_ACTIONS.CUSTOM, user)}
        loadingData={loading}
        users={users?.length ? users : []}
      />
    </>
  );
};
