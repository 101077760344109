import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { renderOrderValue } from 'shared/Tables/table.utils';
import { StockSplit } from 'pages/Orders/Register/types';
import { StyledButtonRow } from 'pages/Orders/Register/Register.styles';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';

export interface HistoricalStockSplitsProps {
  splits?: StockSplit[];
  addStockSplitButton?: JSX.Element;
}

const HistoricalStockSplitsColumnsConfig = [
  { label: 'Date', propName: 'date', propType: 'string', sort: false },
  {
    label: 'Split ratio',
    propName: 'splitRatio',
    propType: 'string',
    sort: false,
  },
  {
    label: 'Shares before stock split',
    propName: 'sharesBeforeStockSplit',
    propType: 'string',
    sort: false,
  },
  {
    label: 'Shares after stock split',
    propName: 'sharesAfterStockSplit',
    propType: 'string',
    sort: false,
  },
];

export const HistoricalStockSplits = ({
  splits,
  addStockSplitButton,
}: HistoricalStockSplitsProps) => {
  return (
    <>
      <TableTitleWithActions
        title="Historical Stock Splits"
        actions={addStockSplitButton && [<StyledButtonRow>{addStockSplitButton}</StyledButtonRow>]}
      />
      <Table>
        <TableHeaderWithMultiSort
          columns={HistoricalStockSplitsColumnsConfig}
          columnAlignment={{
            date: 'left',
            splitRatio: 'right',
            sharesBeforeStockSplit: 'right',
            sharesAfterStockSplit: 'right',
          }}
        />
        <TableBodyWithStates
          hasContent={Boolean(splits?.length)}
          noContentLabel="No entries at the moment."
        >
          {splits?.map((entry, index) => (
            <TableRow key={entry.date + index}>
              <TableCell align="left">
                {renderOrderValue(Boolean(entry.date), entry.date)}
              </TableCell>
              <TableCell align="right">{entry.splitRatio}</TableCell>
              <TableCell align="right">
                {renderOrderValue(Boolean(entry.numberOfSharesBefore), entry.numberOfSharesBefore)}
              </TableCell>
              <TableCell align="right">
                {renderOrderValue(Boolean(entry.numberOfSharesAfter), entry.numberOfSharesAfter)}
              </TableCell>
            </TableRow>
          ))}
        </TableBodyWithStates>
      </Table>
    </>
  );
};
