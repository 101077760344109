import SelectComponent from 'components/Select';
import { Button } from 'components/Button/Button';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { Box } from '@mui/material';
import { Card } from 'components/Card/Card';
import { UnrealizedAccruedFee } from 'utils/types/fees';
import { TransactionType } from 'utils/types/nav';

const unrealizedAccruedFeeTransactionsTypes = [
  { label: 'All Transactions', value: undefined },
  { label: 'Management Fee', value: TransactionType.MANAGEMENT_FEE },
  { label: 'Earnings Fee', value: TransactionType.EARN_FEE },
  { label: 'MEV Fee', value: TransactionType.MEV_FEE },
  { label: 'Fee Adjust', value: TransactionType.FEE_ADJUST },
];

interface FeeDetailsHeaderProps {
  fee: UnrealizedAccruedFee | undefined;
  onTypeChange: (type: TransactionType) => void;
  selectedType: TransactionType | undefined;
}

export const FeesDetailsPageHeader = ({
  fee,
  onTypeChange,
  selectedType,
}: FeeDetailsHeaderProps) => {
  return (
    <Card
      sx={{ padding: 2 }}
      body={
        <Box sx={{ display: 'flex', gap: 1 }}>
          {fee && (
            <Box sx={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
              <Box sx={{ width: 'auto' }}>
                <SelectComponent
                  className="product-icon"
                  value={selectedType}
                  size="medium"
                  placeholder="All Transactions"
                  options={unrealizedAccruedFeeTransactionsTypes}
                  onChange={(type) => onTypeChange(type as TransactionType)}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <Button variant="secondary" emphasis="medium">
                  <DownloadIcon />
                  <span>CSV</span>
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      }
    />
  );
};
