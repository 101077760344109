import Loader from 'components/Loader';
import { TitleArea } from 'components/TitleArea/TitleArea';
// import { FeesDetailsPageTableHeader } from './components/FeesDetailsPageTableHeader';
import { Card } from 'components/Card/Card';
import { Button } from 'components/Button/Button';
import { Alert, Box, Typography } from '@mui/material';
import ProductIcon from 'components/ProductIcon';
import PageTitle from 'components/PageTitle';
import { FeesDetailsTable } from 'shared/Tables/Fees/FeesDetailsTable/FeesDetailsTable';
import { FeesDetailsPageHeader } from './components/FeesDetailsPageHeader';
import { FEES_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import useAppModal from 'hooks/useAppModal';
import { useParams } from 'react-router-dom';
import { useFeesDetailsQuery } from 'pages/Fees/hooks/useFeesDetailsQuery';
import Pagination from 'components/Pagination';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useState } from 'react';
import { DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { ParamsType } from 'hooks/useUrlParams';
import { fieldFilter, getPaginationSliceValues } from 'utils/filters';
import { TransactionType } from 'utils/types/nav';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import { DateTime } from 'utils/datetime';

export const FeesDetailsPage = () => {
  const params = useParams();
  const openModal = useAppModal();

  const { data: fee, refetch } = useFeesDetailsQuery(params.productId!, params.constituentTicker!);

  const [urlParams, setUrlParams] = useState<ParamsType>({});
  const [type, setType] = useState<TransactionType>();

  const filteredTransactions =
    fee?.transactions.filter((transaction) => fieldFilter(transaction.type, type)) ?? [];

  const pagination = useBackendPagination(
    {
      page: urlParams.page ?? DEFAULT_PAGE,
      pageSize: urlParams.pageSize ?? 10,
      total: filteredTransactions.length ?? 0,
    },
    setUrlParams
  );
  const paginationValues = getPaginationSliceValues(
    pagination.page,
    pagination.itemsPerPage,
    filteredTransactions?.length ?? 0
  );

  const openFeesDetailsModal = (type: MODAL_ACTIONS) => {
    openModal(
      {
        modalName: FEES_MODAL,
        modalData: {
          type: type,
          data: fee ? [fee] : [],
        },
      },
      {
        onCloseModalAction: () => {
          refetch();
        },
      }
    );
  };

  const ActionButton = (
    <Button
      variant="primary"
      onClick={() => openFeesDetailsModal(MODAL_ACTIONS.CUSTOM)}
      disabled={fee?.totalUnrealizedAccruedFee === '0'}
    >
      Withdraw
    </Button>
  );
  return (
    <>
      <TitleArea
        title={
          fee ? (
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <ProductIcon
                className="product-icon"
                iconUrl={fee?.product.fundIcon}
                ticker={fee?.product.ticker}
                size="40px"
              />
              <PageTitle>{fee?.product.ticker} PCF Details</PageTitle>
            </Box>
          ) : null
        }
        showBackButton={true}
        actionButton={ActionButton}
      />
      {false ? (
        <Loader />
      ) : (
        <>
          <FeesDetailsPageHeader fee={fee} onTypeChange={setType} selectedType={type} />
          <Card
            headerDivider={false}
            footerDivider={false}
            header={
              <TableTitleWithActions
                title={
                  <Box display={'flex'} gap="16px" alignContent={'center'}>
                    <Typography variant="subheadingMedium" alignContent={'center'}>
                      Unrealized Accrued Fees
                    </Typography>
                    {fee?.lastWithdrawTransaction && (
                      <Alert severity="info">{`Last withdrawal amount  ${
                        fee.lastWithdrawTransaction?.amount
                      } at ${new DateTime(
                        fee.lastWithdrawTransaction?.createdAt
                      ).toDateString()}`}</Alert>
                    )}
                  </Box>
                }
                actions={[
                  <Typography variant="subheadingMedium" alignContent={'center'}>
                    {fee?.totalUnrealizedAccruedFee} {fee?.constituentAsset.ticker}
                  </Typography>,
                ]}
              />
            }
            body={<FeesDetailsTable fees={filteredTransactions.slice(...paginationValues)} />}
            footer={<Pagination {...pagination} />}
          />
        </>
      )}
    </>
  );
};
