// assets
import { NavItem } from 'layouts/DashboardContainer/components/SideNav/SideNavigation.types';
import { ReactComponent as LogoutIcon } from 'assets/Navigation/logout.svg';
import { ReactComponent as AdminIcon } from 'assets/Navigation/admin.svg';
import { ReactComponent as DashboardIcon } from 'assets/Navigation/dashboard.svg';
import { ReactComponent as LedgerIcon } from 'assets/Navigation/ledger.svg';
import { ReactComponent as OrdersIcon } from 'assets/Navigation/orders.svg';
import { ReactComponent as PartnersIcon } from 'assets/Navigation/partners.svg';
import { ReactComponent as ProductsIcon } from 'assets/Navigation/products.svg';
import { ReactComponent as ProfileIcon } from 'assets/Navigation/profile-black.svg';
import { privateRoutesUrls, publicRoutesUrls } from 'router/constants';

export const sideNavItemsMap: NavItem[] = [
  {
    icon: <DashboardIcon />,
    title: 'Dashboard',
    path: privateRoutesUrls.dashboardRoutes.dashboard,
  },
  {
    icon: <LedgerIcon />,
    title: 'Ledger',
    subnav: [
      {
        title: 'NAV Review',
        path: privateRoutesUrls.dashboardRoutes.nav,
      },
      {
        title: 'PCF',
        path: privateRoutesUrls.dashboardRoutes.pcf,
      },
      {
        title: 'Fees',
        path: privateRoutesUrls.dashboardRoutes.feesPage,
      },
    ],
  },
  {
    icon: <OrdersIcon />,
    title: 'Orders',
    subnav: [
      {
        title: 'ETPs',
        path: privateRoutesUrls.dashboardRoutes.orders,
      },
      {
        title: 'Register',
        path: privateRoutesUrls.dashboardRoutes.register,
      },
      {
        title: 'Tokens',
        path: privateRoutesUrls.dashboardRoutes.ordersTokens,
      },
    ],
  },
  {
    icon: <PartnersIcon />,
    title: 'Partners',
    path: privateRoutesUrls.dashboardRoutes.partners,
  },
  {
    icon: <ProductsIcon />,
    title: 'Products',
    subnav: [
      {
        title: 'ETPs',
        path: privateRoutesUrls.dashboardRoutes.etps,
      },
      {
        title: 'Rebalance',
        path: privateRoutesUrls.dashboardRoutes.rebalance,
      },
      {
        title: 'Tokens',
        path: privateRoutesUrls.dashboardRoutes.tokens,
      },
    ],
  },
  {
    icon: <AdminIcon />,
    title: 'Admin',
    subnav: [
      {
        title: 'Calendars',
        path: privateRoutesUrls.dashboardRoutes.calendars,
      },
      {
        title: 'Contacts',
        path: privateRoutesUrls.dashboardRoutes.adminContacts,
      },
      {
        title: 'Exchanges',
        path: privateRoutesUrls.dashboardRoutes.adminExchanges,
      },
      {
        title: 'Users',
        path: privateRoutesUrls.dashboardRoutes.adminUsers,
      },
      {
        title: 'Wallets',
        path: privateRoutesUrls.dashboardRoutes.adminWallets,
      },
      {
        title: 'API Keys',
        path: privateRoutesUrls.dashboardRoutes.adminApiKeys,
      },
    ],
  },
];

export const logoutItem: NavItem = {
  icon: <LogoutIcon />,
  title: 'Logout',
  path: publicRoutesUrls.login,
};

export const profileItem = (title: string = 'User Name'): NavItem => ({
  icon: <ProfileIcon />,
  title: title,
  path: privateRoutesUrls.dashboardRoutes.userSettings,
});
