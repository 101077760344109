import { ProductConstituentAssetType, TokenConstituentAssetType } from 'utils/types/product';
import yesOrNo from 'utils/decorators/yesOrNo';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import titelize from 'utils/decorators/titelize';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import { capitalizeFirstLetter } from 'utils/formatting';
import Tag from 'components/Tag/Tag';

interface ConstituentsTableRowProps {
  constituent: ProductConstituentAssetType | TokenConstituentAssetType;
  index: number;
  editAction: () => void;
  deleteAction: () => void;
  showActionsCell?: boolean;
}

export const ConstituentsTableRow = ({
  constituent,
  editAction,
  deleteAction,
  showActionsCell,
  index,
}: ConstituentsTableRowProps) => {
  return (
    <TableRow
      data-qa-id={`${constituent.name}`}
      key={`${constituent.ticker}${constituent.name}${index}`}
      onClick={editAction}
    >
      <TableCell data-qa-id={`${constituent.ticker}-row`}>{constituent.ticker}</TableCell>
      <TableCell data-qa-id={`${constituent.name}-row`}>{constituent.name}</TableCell>
      <TableCell data-qa-id={`assetClass-${constituent.assetType}`}>
        <Tag label={titelize(constituent.assetType)} variant="yellow" />
      </TableCell>
      <TableCell data-qa-id={`staking-${constituent.isStaking}`}>
        {yesOrNo(constituent.isStaking)}
      </TableCell>
      <TableCell data-qa-id={`interest-${constituent.interestGenerating}`}>
        {yesOrNo(constituent.interestGenerating)}
      </TableCell>
      <TableCell data-qa-id={`leveraged-${constituent.isLeveraged}`}>
        {yesOrNo(constituent.isLeveraged)}
      </TableCell>
      {'isActive' in constituent && (
        <TableCell data-qa-id={`status-${constituent.isActive}`}>
          <Tag
            variant={constituent.isActive ? 'green' : 'red'}
            label={capitalizeFirstLetter(constituent.isActive ? 'Active' : 'Inactive')}
          />
        </TableCell>
      )}
      <TableCell
        data-qa-id="actionBtn"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {showActionsCell && (
          <TrashIcon
            data-qa-id="trashButton"
            type="button"
            onClick={() => deleteAction()}
            className="editIcons"
          />
        )}
      </TableCell>
    </TableRow>
  );
};
