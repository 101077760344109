import { Order } from 'utils/types/orders';
import { ProductType } from 'utils/types/product';
import IndexBasketDeliverablesTable from './components/IndexBasketDeliverablesTable';

export interface ExpectedDeliverablesProps {
  productType: ProductType;
  order?: Order;
}

const IndexBasketDeliverables = ({ productType, order }: ExpectedDeliverablesProps) => {
  return <IndexBasketDeliverablesTable productType={productType} order={order} />;
};

export default IndexBasketDeliverables;
