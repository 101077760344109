import { TableBody } from '@mui/material';
import Table, { TableCell, TableRow } from 'components/Table';
import TooltipContent from 'components/Tooltip/TooltipContent';
import { format } from 'date-fns';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { Order } from 'utils/types/orders';

interface EmptyActualDeliverablesTableProps {
  order?: Order;
}

const EmptyActualDeliverablesTable = ({ order }: EmptyActualDeliverablesTableProps) => {
  const deliveryType = order?.deliveryType;
  const deliverables = order?.deliveries?.expected;

  const EtpCashColumns = [
    { label: 'Asset', propName: 'asset', propType: 'string', sort: false },
    { label: 'Weight', propName: 'weight', propType: 'string', sort: false },
    { label: 'Quantity', propName: 'quantity', propType: 'string', sort: false },
    {
      label: 'Closing Price',
      propName: 'closingPrice',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title="Closing Price"
          description={`The price of the asset at market close (NAV T) with valuation ${order?.settlement?.date}. Target to execute on.`}
        />
      ),
    },
    {
      label: 'Execution Price',
      propName: 'executionPrice',
      propType: 'string',
      sort: false,
      tooltip: <TooltipContent description="Price at which the trade executed" />,
    },
    { label: 'Market Value', propName: 'marketValue', propType: 'string', sort: false },
    {
      label: 'Actual delivery',
      propName: 'actualDelivery',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title={`USD/USDC: -`}
          description={`The FX rate between base currency and delivery currency is -`}
        />
      ),
    },
  ];

  const EtpInKindColumns = [
    { label: 'Asset', propName: 'asset', propType: 'string', sort: false },
    { label: 'Weight', propName: 'weight', propType: 'string', sort: false },
    { label: 'Quantity', propName: 'quantity', propType: 'string', sort: false },
    {
      label: 'Reference Price',
      propName: 'referencePrice',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title="Reference price"
          description={`T-1 Price from PCF with valuation date ${
            deliverables &&
            deliverables[0]?.priceAt &&
            format(new Date(deliverables[0]?.priceAt), DEFAULT_DATE_FORMAT)
          }. This PCF was effective at the time of this order`}
        />
      ),
    },
    { label: 'Market Value', propName: 'marketValue', propType: 'string', sort: false },
    {
      label: 'Actual delivery',
      propName: 'actualDelivery',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title={`USD/USDC: -`}
          description={`The FX rate between base currency and delivery currency is -`}
        />
      ),
    },
  ];

  return (
    <Table noPadding>
      <TableHeaderWithMultiSort
        columns={deliveryType === 'CASH' ? EtpCashColumns : EtpInKindColumns}
      />
      <TableBody>
        {deliveryType === 'CASH' && (
          <TableRow hoverEffects={false}>
            {/* Asset */}
            <TableCell key="ticker">TOTAL</TableCell>
            {/* Weight */}
            <TableCell key="ticker">-</TableCell>
            {/* Quantity */}
            <TableCell key="coinQty"></TableCell>
            {/* Closing Price */}
            <TableCell key="closingPrice"></TableCell>
            {/* Execution Price */}
            <TableCell key="price"></TableCell>
            {/* Market Value */}
            <TableCell key="value">-</TableCell>
            {/* Actual Delivery */}
            <TableCell key="actualDelivery">-</TableCell>
          </TableRow>
        )}
        {deliveryType === 'IN_KIND' && (
          <TableRow hoverEffects={false}>
            {/* Asset */}
            <TableCell key="ticker">TOTAL</TableCell>
            {/* Weight */}
            <TableCell key="ticker">-</TableCell>
            {/* Quantity */}
            <TableCell key="coinQty"></TableCell>
            {/* Reference Price */}
            <TableCell key="closingPrice"></TableCell>
            {/* Market Value */}
            <TableCell key="value">-</TableCell>
            {/* Market Delivery */}
            <TableCell key="actualDelivery">-</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default EmptyActualDeliverablesTable;
