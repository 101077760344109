import Notification from 'components/Notification';
import PCFTableActionbar from 'shared/Tables/PCFTable/PCFTableActionbar';
import PCFTableRow from 'shared/Tables/PCFTable/PCFTableRow';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { ParamsType } from 'hooks/useUrlParams';
import { PortfolioComposition } from 'utils/types/pcfs';
import { TableHeaderColumn } from 'components/Table/Table.types';
import { getPCFsTableColumnsConfig } from './tableColumnsConfig';
import { useBatchOperations } from 'shared/Tables/PCFTable/useBatchOperations';
import { useEffect, useMemo } from 'react';
import { useUserPermissions } from 'store/user/selectors';

interface PCFTableProps {
  editAction?: (pcf: PortfolioComposition) => void;
  pcfs: PortfolioComposition[];
  loadingData?: boolean;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
  dateRangeHasHoliday?: boolean;
}

const PCFTable = ({
  editAction,
  pcfs,
  loadingData,
  setUrlParams,
  urlParams,
  dateRangeHasHoliday,
}: PCFTableProps) => {
  const { checkedItems, headerCheckboxProps, isChecked, onCheckedChange } = useBatchOperations(
    pcfs.map((pcf) => ({ _id: pcf._id }))
  );
  const permissions = useUserPermissions();
  const showBatchOperations = permissions?.canDownloadPcf ? true : undefined;
  const Actionbar = <PCFTableActionbar checkedItems={checkedItems} pcfs={pcfs} />;
  const columns: TableHeaderColumn[] = useMemo(
    () =>
      getPCFsTableColumnsConfig(
        Boolean(permissions?.isIssuer),
        Boolean(permissions?.canComparePcfs)
      ),
    [permissions]
  );
  const noContentLabel = dateRangeHasHoliday
    ? 'Due to a holiday there are no PCFs to be shown for this date.'
    : 'No PCFs at the moment.';

  useEffect(() => {
    headerCheckboxProps.onChange(false);
  }, [urlParams]);

  return (
    <>
      {dateRangeHasHoliday === true && (
        <Notification
          messageMaxWidth={true}
          message="There is a low number of PCFs shown here due to a holiday within this date range."
          multiline={'false'}
          title="Decreased number of PCFs"
          variant="info"
          gapBelow={16}
        />
      )}
      <Table tabsComponent={Actionbar} noPadding>
        <TableHeaderWithMultiSort
          showActionsCell
          columns={columns}
          checkboxProps={showBatchOperations && headerCheckboxProps}
          onColumnSort={(columns) => {
            setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
          }}
        />
        <TableBodyWithStates
          loadingData={Boolean(loadingData)}
          hasContent={!isEmpty(pcfs)}
          noContentLabel={noContentLabel}
        >
          {pcfs?.map((pcf) => (
            <PCFTableRow
              key={pcf._id}
              pcf={pcf}
              editAction={() => {
                if (editAction) editAction(pcf);
              }}
              showActionsCell
              checkboxProps={
                showBatchOperations && {
                  checked: isChecked(pcf._id),
                  onChange: () => onCheckedChange(pcf._id),
                }
              }
              columns={columns}
            />
          ))}
        </TableBodyWithStates>
      </Table>
    </>
  );
};

export default PCFTable;
