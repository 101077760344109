import TableCell from './TableCell';
import { ComponentPropsWithRef, useId, useState } from 'react';
import { Popover, useTheme } from '@mui/material';
import Tag from 'components/Tag/Tag';

type TableShowMoreCellProps = ComponentPropsWithRef<typeof TableCell> & {
  items: string[];
  colSpan?: number;
};

export const TableShowMoreCell = ({ items, colSpan, ...props }: TableShowMoreCellProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = useId();

  const [firstItem, ...restItems] = items ?? [];

  return (
    <TableCell colSpan={colSpan} onClick={(event) => event.stopPropagation()}>
      {firstItem && <Tag label={firstItem} variant="yellow" />}
      {Boolean(restItems.length) && (
        <>
          <span
            style={{
              marginLeft: theme.spacers['spacing-08'],
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            + {restItems.length} more
          </span>
          <Popover
            id="popover-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ul onClick={handleClose}>
              {restItems.map((item, index) => (
                <li
                  style={{
                    padding: `${theme.spacers['spacing-08']} ${theme.spacers['spacing-16']}`,
                  }}
                  key={`${id}_${item}_${index}`}
                >
                  {item}
                </li>
              ))}
            </ul>
          </Popover>
        </>
      )}
    </TableCell>
  );
};
