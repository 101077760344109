import { styled as MuiStyled } from '@mui/material';

//components
import TableRow from 'components/Table/TableRow';
import Button from 'components/Button';
import { TableCell } from 'components/Table/TableCell';

export const StyledAddButtonRowWrapper = MuiStyled(TableCell)`
  padding: 16px;
  padding-left: 56px;
  svg {
    margin-right: ${({ theme }) => theme.spacers['spacing-08']};
  }
`;

export const StyledCalendarEventRow = MuiStyled(TableRow)`
  td:first-child {
    padding-left: 56px;
  }
`;

export const StyledTableCellWithChevron = MuiStyled('td')`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.tertiary.dark}`};
  height: 48px;
  min-width: 350px;
  display: flex;
  text-align: end;
  align-items: center;
  div {
    padding-right: ${({ theme }) => theme.spacers['spacing-16']};
  }
`;

export const StyledButton = MuiStyled(Button)`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export const MuiStyledTableCellWithChevron = MuiStyled(TableCell)(({ theme }) => ({
  minWidth: '350px',
  display: 'flex',
  textAlign: 'end',
  alignItems: 'center',
  'svg:hover': {
    cursor: 'pointer',
  },
  div: {
    paddingRight: theme.spacers['spacing-16'],
  },
}));

export const MuiCalendarTableCell = MuiStyled(TableCell)(({ theme }) => ({
  height: '48px',
}));

export const MuiStyledCalendarEventRow = MuiStyled(TableRow)(({ theme }) => ({
  'td:first-child': {
    paddingLeft: theme.spacers['spacing-48'],
  },
}));

export const MuiStyledAddButtonRowWrapper = MuiStyled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingLeft: '56px',
  svg: {
    marginRight: theme.spacers['spacing-08'],
  },
}));
