import { useNavigate } from 'react-router-dom';
import { RebalanceTradesCard } from './components/RebalanceTradesCard';
import { RebalancesActionBar } from './components/RebalancesActionBar/RebalancesActionBar';
import { privateRoutesUrls } from 'router/constants';
import { useRebalanceQuery } from './hooks/useRebalance';
import { ParamsType, useUrlParams } from 'hooks/useUrlParams';
import { DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { DateTime } from 'utils/datetime';
import Pagination from 'components/Pagination';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { RebalancePageBody } from './components/RebalancePageBody';
import { compileUrlToRebalanceDetails, downloadAllRebalancesXLS } from './utils/utils';
import { REBALANCE_STATUS_LABEL, REBALANCE_TAB_STATUS } from 'utils/constants/rebalance';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { Card } from 'components/Card/Card';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';

const itemsPerPageOptions = [
  { label: '4', value: 4 },
  { label: '10', value: 10 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '250', value: 250 },
];

const sharedDefaults = {
  page: DEFAULT_PAGE,
  pageSize: 4,
};

const upcomingDefaults = {
  ...sharedDefaults,
  tab: REBALANCE_TAB_STATUS.UPCOMING,
  valuationDate: new DateTime().toDateString(),
};

export const RebalancePage = () => {
  const navigate = useNavigate();
  const { urlParams, setUrlParams } = useUrlParams(
    upcomingDefaults,
    privateRoutesUrls.dashboardRoutes.rebalance,
    true
  );

  const {
    data: rebalanceData,
    isLoading: isLoadingRebalance,
    refetch,
  } = useRebalanceQuery({
    ...urlParams,
  });

  const rebalanceBackendPagination = useBackendPagination(rebalanceData?.pagination, setUrlParams);

  const navigateToDetails = (productId: string, date: string) => {
    navigate(compileUrlToRebalanceDetails(productId, date));
  };

  const switchTab = (params: ParamsType) => {
    const sharedParams = { ...sharedDefaults, ...params };

    if (params.tab === REBALANCE_TAB_STATUS.TODAY) {
      setUrlParams({ ...sharedParams, valuationDate: new DateTime().toDateString() });
    }
    if (params.tab === REBALANCE_TAB_STATUS.HISTORIC) {
      setUrlParams({
        ...sharedParams,
        valuationDate: new DateTime().subtract(1, 'days').toDateString(),
      });
    }
    if (params.tab === REBALANCE_TAB_STATUS.UPCOMING) {
      setUrlParams({
        ...sharedParams,
      });
    }
  };

  const tabs = [
    [REBALANCE_STATUS_LABEL.UPCOMING, REBALANCE_TAB_STATUS.UPCOMING],
    [REBALANCE_STATUS_LABEL.TODAY, REBALANCE_TAB_STATUS.TODAY],
    [REBALANCE_STATUS_LABEL.HISTORIC, REBALANCE_TAB_STATUS.HISTORIC],
  ].map((tab) => ({ name: tab[0], param: tab[1] }));

  const statusToTitleMapping = {
    [REBALANCE_STATUS_LABEL.UPCOMING.toLowerCase()]: 'Upcoming Rebalances',
    [REBALANCE_STATUS_LABEL.TODAY.toLowerCase()]: "Today's Rebalances",
    [REBALANCE_STATUS_LABEL.HISTORIC.toLowerCase()]: 'Past Rebalances',
  };

  return (
    <>
      <TitleArea
        title={'Rebalance'}
        tabConfig={{
          tabs: tabs,
          setUrlParamsWithDefaults: (params) => {
            switchTab({
              ...urlParams,
              ...params,
            });
          },
          active: urlParams.tab as string,
        }}
      />
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          background: 'white',
          marginBottom: '24px',
          minWidth: 'min-content',
        }}
        header={
          <TableTitleWithActions
            title={statusToTitleMapping[urlParams.tab as REBALANCE_STATUS_LABEL] || 'Rebalances'}
            actions={[
              <RebalancesActionBar
                product={urlParams.product as string}
                onProductChange={(product) => setUrlParams({ ...urlParams, product: product })}
                tab={urlParams.tab as string}
                date={urlParams.valuationDate as string}
                onDateChange={(date) =>
                  setUrlParams({ ...urlParams, valuationDate: new DateTime(date).toDateString() })
                }
                xlsButton={{
                  onClick: () =>
                    downloadAllRebalancesXLS(
                      rebalanceData?.data,
                      urlParams.valuationDate as string
                    ),
                  label: 'Download all (XLS)',
                  disabled:
                    !Array.isArray(rebalanceData?.data) || rebalanceData?.data?.length === 0,
                }}
                approveButton={{
                  onClick: () => {},
                  label: 'Approve All',
                  disabled: true,
                  size: 'large',
                }}
                showDateFilter={Boolean(urlParams.tab !== REBALANCE_TAB_STATUS.TODAY)}
              />,
            ]}
          />
        }
        headerDivider={false}
        body={
          <RebalancePageBody
            isLoading={isLoadingRebalance}
            noContent={Boolean(!rebalanceData?.data?.length)}
            noContentLabel={
              urlParams.tab === REBALANCE_TAB_STATUS.TODAY
                ? 'No product rebalances necessary at this time'
                : 'No product rebalances found for the selected date'
            }
          >
            {rebalanceData?.data?.map((rebalance, index) => (
              <RebalanceTradesCard
                key={`${index}-${rebalance._id}`}
                rebalance={rebalance}
                navigateToDetails={() =>
                  navigateToDetails(rebalance.product._id ?? rebalance.productId, rebalance.date)
                }
                isPastTab={urlParams.tab === REBALANCE_TAB_STATUS.HISTORIC}
                refetchData={refetch}
              />
            ))}
          </RebalancePageBody>
        }
        footerDivider={false}
        footer={
          Boolean(rebalanceData?.data?.length) && (
            <Pagination {...rebalanceBackendPagination} itemsPerPageOptions={itemsPerPageOptions} />
          )
        }
      />
    </>
  );
};
