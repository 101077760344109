import Table from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { Deliverable, Order } from 'utils/types/orders';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { ProductType } from 'utils/types';
import { TableBody, Typography } from '@mui/material';
import { formatToCurrency } from 'utils/formatting';
import TooltipContent from 'components/Tooltip/TooltipContent';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import BigNumber from 'bignumber.js';

interface IndexBasketDeliverablesTableProps {
  productType: ProductType;
  order?: Order;
}

const IndexBasketDeliverablesTable = ({
  productType,
  order,
}: IndexBasketDeliverablesTableProps) => {
  const deliverables = order?.deliveries?.underlyingExpected || order?.deliveries?.expected;

  const etpColumns = [
    { label: 'Asset', propName: 'asset', propType: 'string', sort: false },
    { label: 'Weight', propName: 'weight', propType: 'string', sort: false },
    { label: 'Quantity', propName: 'quantity', propType: 'string', sort: false },
    {
      label: 'Reference Price',
      propName: 'referencePrice',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title="Reference price"
          description={`T-1 Price from PCF with valuation date ${
            deliverables &&
            deliverables[0]?.priceAt &&
            format(new Date(deliverables[0]?.priceAt), DEFAULT_DATE_FORMAT)
          }. This PCF was effective at the time of this order`}
        />
      ),
    },
    { label: 'Market Value', propName: 'marketValue', propType: 'string', sort: false },
  ];

  const totalsRow = (
    <TableRow key="totals" isExpanded>
      <TableCell>TOTAL</TableCell>
      <TableCell>
        {deliverables
          ?.reduce(
            (totalWeight, deliverable) =>
              totalWeight.plus(new BigNumber(deliverable.weight || 0).multipliedBy(100)),
            new BigNumber(0)
          )
          .toFixed(2, BigNumber.ROUND_HALF_UP)}
        %
      </TableCell>
      <TableCell></TableCell>
      <TableCell key="priceT1Nav" hideOnSmallScreen></TableCell>
      <TableCell>
        {formatToCurrency(
          new BigNumber(order?.deliveries?.expectedTotalCash || 0)
            .decimalPlaces(2, BigNumber.ROUND_HALF_UP)
            .toNumber(),
          order?.deliveries?.expectedTotalCurrency
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {productType === 'ETP' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort columns={etpColumns} />
          <TableBody>
            {deliverables?.length ? (
              deliverables
                ?.filter((deliverable) => Boolean(deliverable.weight))
                .map((deliverable: Deliverable) => (
                  <TableRow key={deliverable.ticker}>
                    {/* Asset */}
                    <TableCell key="ticker">{deliverable?.ticker}</TableCell>
                    {/* Weight */}
                    <TableCell key="weight">
                      {deliverable?.weight
                        ? `${new BigNumber(deliverable?.weight)
                            .multipliedBy(100)
                            .toFixed(2, BigNumber.ROUND_HALF_UP)}%`
                        : '-'}
                    </TableCell>
                    {/* Quantity */}
                    <TableCell key="coinQty">{deliverable?.amount}</TableCell>
                    {/* Reference Price */}
                    <TableCell key="priceT1Nav">
                      {formatToCurrency(deliverable.price, deliverable?.currency)}
                    </TableCell>
                    {/* Market Value */}
                    <TableCell key="value">
                      {formatToCurrency(deliverable.totalInCurrency, deliverable?.currency)}
                    </TableCell>
                  </TableRow>
                ))
                .concat([totalsRow])
            ) : (
              <TableRow>
                {/* Asset */}
                <TableCell key="ticker">
                  <Typography variant="labelMedium">Total</Typography>
                </TableCell>
                {/* Weight */}
                <TableCell key="weight">-</TableCell>
                {/* Quantity */}
                <TableCell key="coinQty">-</TableCell>
                {/* Reference Price */}
                <TableCell key="priceT1Nav">-</TableCell>
                {/* Market Value */}
                <TableCell key="value">-</TableCell>
                {/* Actual Delivery */}
                <TableCell key="expectedTotalWithCash">-</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default IndexBasketDeliverablesTable;
