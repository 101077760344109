import { DEFAULT_PAGE } from 'shared/Tables/table.utils';
import Button from 'components/Button';
import { privateRoutesUrls } from 'router/constants';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useUrlParams } from 'hooks/useUrlParams';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { Card } from 'components/Card/Card';
import Pagination from 'components/Pagination';
import FeesTable from 'shared/Tables/Fees/FeesTable/FeesTable';
import { FEES_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import useAppModal from 'hooks/useAppModal';
import { generatePath, useNavigate } from 'react-router-dom';
import { useFeesListQuery } from 'pages/Fees/hooks/useFeesListQuery';
import { useBatchOperations } from './useBatchOperations';
import { useMemo } from 'react';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import ProductSelector from 'components/ProductSelector';
import { Box } from '@mui/material';

const defaultParams = {
  page: DEFAULT_PAGE,
  pageSize: 10,
};

export const FeesPage = () => {
  const openModal = useAppModal();
  const navigate = useNavigate();

  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    defaultParams,
    privateRoutesUrls.dashboardRoutes.feesPage
  );

  const { data, isLoading, refetch } = useFeesListQuery(urlParams);

  const fees = useMemo(() => data?.data ?? [], [data?.data]);
  const batchOperations = useBatchOperations(
    fees
      .filter((fee) => fee.totalUnrealizedAccruedFee !== '0')
      .map((fee) => ({
        _id: `${fee.product._id}_${fee.constituentAsset.ticker}`,
      }))
  );

  const checkedItems = useMemo(
    () =>
      fees.filter((fee) =>
        batchOperations.checkedItems.some(
          ({ _id }) => _id === `${fee.product._id}_${fee.constituentAsset.ticker}`
        )
      ),
    [batchOperations.checkedItems, fees]
  );

  const openFeesModal = (type: MODAL_ACTIONS) => {
    openModal(
      {
        modalName: FEES_MODAL,
        modalData: {
          type: type,
          data: checkedItems,
        },
      },
      {
        onCloseModalAction: () => {
          refetch();
        },
      }
    );
  };

  const tableTopButton = (
    <Button
      data-qa-id="bulkWithdrawButton"
      size="medium"
      onClick={() => openFeesModal(MODAL_ACTIONS.CUSTOM)}
      disabled={checkedItems.length === 0}
    >
      Bulk Withdraw
    </Button>
  );

  const pagination = useBackendPagination(
    { page: data?.page, pageSize: data?.pageSize, total: data?.total } ?? null,
    setUrlParams
  );

  return (
    <>
      <TitleArea
        title={'Management Fees'}
        actionButton={tableTopButton}
        tabConfig={{
          setUrlParamsWithDefaults: (data) => {
            setUrlParamsWithDefaults(data);
          },
          active: urlParams.tab as string,
        }}
      />
      <Card
        noPadding
        header={
          <Box p={3} paddingBottom={1}>
            <TableTitleWithActions
              title="Unrealized Accrued Fees"
              actions={[
                <ProductSelector
                  size="medium"
                  value={urlParams.productId as string}
                  onChange={(value) =>
                    setUrlParams((prevState) => ({
                      ...prevState,
                      productId: value as string,
                    }))
                  }
                />,
              ]}
            />
          </Box>
        }
        body={
          <Box p={3}>
            <FeesTable
              urlParams={urlParams}
              setUrlParams={setUrlParams}
              fees={fees}
              loadingData={isLoading}
              batchOperations={batchOperations}
              editAction={(fee) =>
                navigate(
                  generatePath(privateRoutesUrls.dashboardRoutes.feesDetails, {
                    productId: fee.product._id,
                    constituentTicker: fee.constituentAsset.ticker,
                  })
                )
              }
            />
          </Box>
        }
        footer={<Pagination {...pagination} />}
      />
    </>
  );
};
