import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';
import { apiUrls } from 'utils/constants/apiUrls';
import { PaginationWithDataResponse } from 'utils/types';
import { UnrealizedAccruedFee } from 'utils/types/fees';

export async function loadFees(params?: ParamsType) {
  try {
    const { data: fees } = await axiosInstance.get<
      PaginationWithDataResponse<UnrealizedAccruedFee[]>
    >(params ? `${apiUrls.uafs}?${getQueryString(params)}` : apiUrls.uafs);
    return fees;
  } catch (err) {
    return errorHandler(err, 'Error while loading instruments');
  }
}

export async function loadFeesDetails(productId: string, constituentTicker: string) {
  try {
    const { data: fees } = await axiosInstance.get<UnrealizedAccruedFee>(
      `${apiUrls.uafs}/productId=${productId}/constituentTicker=${constituentTicker}`
    );
    return fees;
  } catch (err) {
    return errorHandler(err, 'Error while loading instruments');
  }
}

export async function withdrawFees(uafs: { productId: string; constituentTicker: string }[]) {
  try {
    const { data: fees } = await axiosInstance.post<UnrealizedAccruedFee>(
      `${apiUrls.uafs}/withdraw`,
      uafs
    );
    return fees;
  } catch (err) {
    return errorHandler(err, 'Error while loading instruments');
  }
}
