import { FULL_SPAN, TableCell } from 'components/Table/TableCell';
import { PartnerContactsDetailsTableRow } from './PartnerContactsDetailsTableRow';
import { Contact } from 'utils/types/contacts';
import { Table, TableHead, Typography } from '@mui/material';
import { TableRow } from 'components/Table/TableRow';

interface PartnerContactsDetailsTableProps {
  contacts: Contact[];
  editAction: (contact: Contact) => void;
  deleteAction: (contact: Contact) => void;
}

export const PartnerContactsDetailsTable = ({
  contacts,
  editAction,
  deleteAction,
}: PartnerContactsDetailsTableProps) => {
  if (!contacts.length) {
    return (
      <TableRow key={'empty-row'}>
        <TableCell style={{ alignItems: 'center' }} colSpan={FULL_SPAN}>
          No Contacts.
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableCell align="left"></TableCell>
        <TableCell align="left">
          <Typography color="grey.600" variant="labelMedium">
            User name
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography color="grey.600" variant="labelMedium">
            User email
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography color="grey.600" variant="labelMedium">
            User type
          </Typography>
        </TableCell>
        <TableCell align="left" width="40%" colSpan={2}>
          <Typography color="grey.600" variant="labelMedium">
            Distribution Lists
          </Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableHead>
      {contacts.length && (
        <>
          {contacts.map((contact) => (
            <PartnerContactsDetailsTableRow
              editAction={() => {
                editAction(contact);
              }}
              deleteAction={() => {
                deleteAction(contact);
              }}
              contact={contact}
            />
          ))}
        </>
      )}
    </Table>
  );
};
