import { CONTACT_LIST_TYPE } from 'utils/types/contacts';

export const contactListTypesToolTipText = {
  [CONTACT_LIST_TYPE.ORDER]: {
    title: 'Orders',
    content:
      'Receive notifications for when an order is placed, confirmed, ready, settled, deliverables received, shares received, cancelled, and a daily order summary.',
  },
  [CONTACT_LIST_TYPE.PRODUCT]: {
    title: 'Product',
    content:
      'Receive notifications for when a product is created, updated, and activated / deativated.',
  },
  [CONTACT_LIST_TYPE.NON_PRODUCT]: {
    title: 'Non-Product',
    content: ' Any other type of email / non fund specific reporting.',
  },
  [CONTACT_LIST_TYPE.PCF]: {
    title: 'PCF',
    content: 'This sends confirmed PCF Notifications to the contacts in this list.',
  },
  [CONTACT_LIST_TYPE.CONSOLIDATED_PCFS]: {
    title: 'Consolidated PCFs',
    content: `This sends consolidated PCF's Notifications to the contacts in this list.`,
  },
  [CONTACT_LIST_TYPE.SETTLEMENT_SUMMARY]: {
    title: 'Settlement Summary',
    content: 'This sends order settlement summaries to the contacts in this list.',
  },
  [CONTACT_LIST_TYPE.BLOOMBERG_HOLDINGS_FILE]: {
    title: 'Bloomberg Holdings File',
    content: `This sends Bloomberg holdings file Notifications to the contacts in this list.`,
  },
  [CONTACT_LIST_TYPE.BLOOMBERG_NAV_FILE]: {
    title: 'Bloomberg NAV File',
    content: `This sends Bloomberg NAV file Notifications to the contacts in this list.`,
  },
  [CONTACT_LIST_TYPE.FINAL_TERMS_AND_ISS]: {
    title: 'ISS & FT',
    content: 'This sends FT and ISS documents to the contacts in this list.',
  },
};
