import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { Stack, Typography } from '@mui/material';
import { TableHeaderColumn } from 'components/Table/Table.types';
import { Checkbox } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/plus-icon.svg';
import { ReactComponent as MinusIcon } from 'assets/minus-icon.svg';
import { colors } from 'theme/themes';
import { TransactionLedger } from 'utils/types/nav';
import { DateTime } from 'utils/datetime';

type FeeTableRowProps = {
  fee: TransactionLedger;
  columns: TableHeaderColumn[];
  checked: Boolean;
};

const renderCell = (fee: TransactionLedger, column: TableHeaderColumn, checked: Boolean) => {
  switch (column.propName) {
    case 'transaction':
      return (
        <TableCell nowrap>
          <Stack flexDirection={'row'} gap={1}>
            <Checkbox
              icon={<MinusIcon />}
              checkedIcon={<PlusIcon />}
              sx={{ p: 0 }}
              checked
              disabled
            />
            <Typography alignContent={'center'} variant="labelMedium">
              {fee.type ?? '-'}{' '}
            </Typography>
          </Stack>
        </TableCell>
      );
    case 'date':
      return (
        <TableCell data-qa-id={`${fee.createdAt}`} align="right">
          <Typography>
            {fee.createdAt ? new DateTime(fee.createdAt).toDateString() : '-'}{' '}
          </Typography>
        </TableCell>
      );
    case 'amount':
      return (
        <TableCell data-qa-id={`${fee.amount}`} align="right">
          <Typography style={{ color: checked ? colors.green[80] : colors.red[80] }}>
            {fee.amount ?? '-'}{' '}
          </Typography>
        </TableCell>
      );
  }
};

export const FeesDetailsTableRow = ({ fee, columns, checked }: FeeTableRowProps) => {
  return (
    <TableRow data-qa-id={`${fee._id}-row`} key={fee._id}>
      {columns.filter((col) => !col.hideColumn).map((col) => renderCell(fee, col, checked))}
    </TableRow>
  );
};
