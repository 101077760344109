import { ReactNode } from 'react';
import TableCell, { FULL_SPAN } from './TableCell';
import TableRow from './TableRow';

export const EmptyRow = ({ children }: { children: ReactNode }) => {
  return (
    <TableRow style={{ height: '84px' }} hoverEffects={false}>
      <TableCell colSpan={FULL_SPAN} style={{ borderBottom: 'none', textAlign: 'center' }}>
        {children}
      </TableCell>
    </TableRow>
  );
};
