import { FULL_SPAN, TableCell } from 'components/Table/TableCell';
import { Contact, getContactType } from 'utils/types/contacts';
import { TableRow } from 'components/Table/TableRow';

interface EditContactListTablePartnerRowDetailsProps {
  contacts: Contact[];
  isExpanded?: boolean;
}

export const EditContactListTablePartnerRowDetails = ({
  contacts,
  isExpanded,
}: EditContactListTablePartnerRowDetailsProps) => {
  if (!contacts.length) {
    return (
      <TableRow key={'empty-row'}>
        <TableCell style={{ alignItems: 'center' }} colSpan={FULL_SPAN}>
          No Contacts.
        </TableCell>
      </TableRow>
    );
  }
  return (
    <>
      <TableRow key={'edit-contacts-list-table-header'}>
        <TableCell align="left"></TableCell>
        <TableCell align="left" width={'25%'}>
          User name
        </TableCell>
        <TableCell align="left" width={'25%'}>
          User email
        </TableCell>
        <TableCell align="left" width={'50%'} colSpan={2}>
          User type
        </TableCell>
      </TableRow>
      {contacts.length && (
        <>
          {contacts.map((contact, idx) => (
            <TableRow
              isExpanded={isExpanded}
              data-qa-id={`row-${contact.name}`}
              key={`${idx}_${contact._id}`}
            >
              <TableCell></TableCell>
              <TableCell data-qa-id={`name-${contact.name}`} width={'25%'}>
                {contact.name}
              </TableCell>
              <TableCell data-qa-id={`email-${contact.name}`} width={'25%'}>
                {contact.email}
              </TableCell>
              <TableCell data-qa-id={`usertype-${contact.name}`} width={'50%'} colSpan={2}>
                {getContactType(contact.type)}
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
};
