import { Contact, getContactType, PartnerContact } from 'utils/types/contacts';
import { TableCell } from 'components/Table/TableCell';
import { Checkbox, CheckboxProps } from 'components/BooleanInputs';
import { MuiStyledTableCellChevron } from 'shared/Tables/ContactsTable/ContactsTable.styles';
import { TableRow } from 'components/Table/TableRow';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';

interface EditContactListTableRowProps {
  contact: Contact;
  showActionsCell?: boolean;
  deleteAction?: (data: { contact?: Contact; partner?: PartnerContact }) => void;
  checkboxProps?: CheckboxProps;
}

export const EditContactListTableRow = ({
  contact,
  checkboxProps,
  deleteAction,
}: EditContactListTableRowProps) => {
  return (
    <TableRow data-qa-id={`row-${contact.name}`}>
      {checkboxProps && (
        <TableCell data-qa-id={`${contact.name}-checkbox`}>
          <Checkbox {...checkboxProps} />
        </TableCell>
      )}
      <MuiStyledTableCellChevron></MuiStyledTableCellChevron>
      <TableCell data-qa-id={`name-${contact.name}`}>{contact.name}</TableCell>
      <TableCell data-qa-id={`partner-${contact.name}`}>{getContactType(contact.type)}</TableCell>
      <TableCell data-qa-id={`email-${contact.name}`}>{contact.email}</TableCell>
      <TableCell data-qa-id="editIcons">
        {deleteAction && (
          <TrashIcon
            data-qa-id="trashIcon"
            onClick={(event) => {
              deleteAction({ contact: contact });
              event.stopPropagation();
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
