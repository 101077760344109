import Notification from 'components/Notification';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { ParamsType } from 'hooks/useUrlParams';
import { FeesTableColumnsConfig } from './tableColumnsConfig';
import { FeesTableRow } from './FeesTableRow';
import { UnrealizedAccruedFee } from 'utils/types/fees';
import { useBatchOperations } from 'shared/Tables/PCFTable/useBatchOperations';

interface FeesTableProps {
  editAction?: (fee: UnrealizedAccruedFee) => void;
  fees: UnrealizedAccruedFee[];
  loadingData?: boolean;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
  dateRangeHasHoliday?: boolean;
  batchOperations: ReturnType<typeof useBatchOperations>;
}

const FeesTable = ({
  editAction,
  fees,
  loadingData,
  setUrlParams,
  urlParams,
  dateRangeHasHoliday,
  batchOperations,
}: FeesTableProps) => {
  const showBatchOperations = true;
  const { isChecked, onCheckedChange, headerCheckboxProps } = batchOperations;

  const noContentLabel = 'No Management Fees for the selected date.';

  return (
    <>
      {dateRangeHasHoliday === true && (
        <Notification
          messageMaxWidth={true}
          message="There is a low number of PCFs shown here due to a holiday within this date range."
          multiline={'false'}
          title="Decreased number of PCFs"
          variant="info"
          gapBelow={16}
        />
      )}
      <Table noPadding>
        <TableHeaderWithMultiSort
          columns={FeesTableColumnsConfig}
          checkboxProps={showBatchOperations && headerCheckboxProps}
          onColumnSort={(columns) => {
            setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
          }}
        />
        <TableBodyWithStates
          loadingData={Boolean(loadingData)}
          hasContent={!isEmpty(fees)}
          noContentLabel={<>{noContentLabel}</>}
        >
          {fees?.map((fee) => (
            <FeesTableRow
              key={`${fee.product._id}_${fee.constituentAsset.ticker}`}
              fee={fee}
              editAction={() => {
                if (editAction) editAction(fee);
              }}
              showActionsCell
              checkboxProps={
                showBatchOperations && {
                  checked: isChecked(`${fee.product._id}_${fee.constituentAsset.ticker}`),
                  disabled: fee.totalUnrealizedAccruedFee === '0',
                  onChange: () =>
                    onCheckedChange(`${fee.product._id}_${fee.constituentAsset.ticker}`),
                }
              }
              columns={FeesTableColumnsConfig}
            />
          ))}
        </TableBodyWithStates>
      </Table>
    </>
  );
};

export default FeesTable;
