import UsersTable from 'shared/Tables/UsersTable/UsersTable';
import useAppModal from 'hooks/useAppModal';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { PartnersUser } from 'utils/types';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import Button from 'components/Button';
import { USER_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { USER_TAB_STATUS, USERS_STATUS_LABEL } from 'utils/constants/users';
import { privateRoutesUrls } from 'router/constants';
import { useAuthenticatedUser, useUserPermissions } from 'store/user/selectors';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useState } from 'react';
import { useUrlParams } from 'hooks/useUrlParams';
import { useUsersPerCompany } from 'pages/Partners/hooks/useUsersPerCompany';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { Card } from 'components/Card/Card';
import { capitalize } from '@mui/material';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';

export const UsersPage = () => {
  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    { page: DEFAULT_PAGE, pageSize: DEFAULT_ITEMS_PER_PAGE, tab: USER_TAB_STATUS.ACTIVE },
    privateRoutesUrls.dashboardRoutes.adminUsers
  );

  const openModal = useAppModal();
  const permissions = useUserPermissions();
  const loggedInUser = useAuthenticatedUser();
  const [refreshData, setRefreshData] = useState(false);
  const {
    users,
    loading,
    pagination: paginationResponse,
  } = useUsersPerCompany(loggedInUser.user?.organization.companyId ?? '', refreshData, urlParams);
  const pagination = useBackendPagination(paginationResponse, setUrlParams);

  const canInviteUser = permissions?.canInviteCompanyUsers;

  const openUserModal = (type: MODAL_ACTIONS, user?: PartnersUser, reset2FA?: boolean) => {
    openModal(
      {
        modalName: USER_MODAL,
        modalData: {
          data: user,
          companyData: {
            id: loggedInUser.user?.organization.companyId,
            name: loggedInUser.user?.organization.name,
          },
          type: type,
          custom: {
            reset2FA: Boolean(reset2FA),
          },
        },
      },
      {
        onCloseModalAction: () => setRefreshData(!refreshData),
      }
    );
  };

  const createUserButton = canInviteUser && (
    <Button
      data-qa-id="inviteUserButton"
      variant="interactive"
      size="medium"
      onClick={() => openUserModal(MODAL_ACTIONS.CREATE)}
    >
      <span>Invite User</span>
      <PlusIcon />
    </Button>
  );
  const tabs = [
    [USERS_STATUS_LABEL.ACTIVE, USER_TAB_STATUS.ACTIVE],
    [USERS_STATUS_LABEL.PENDING, USER_TAB_STATUS.INVITED],
  ].map((tab) => ({ name: tab[0], param: tab[1] }));

  return (
    <>
      <TitleArea
        title={'Users'}
        actionButton={createUserButton}
        tabConfig={{
          tabs: tabs,
          setUrlParamsWithDefaults: (data) => {
            setUrlParamsWithDefaults(data);
          },
          active: urlParams.tab as string,
        }}
      />
      <Card
        header={<TableTitleWithActions title={`${capitalize(String(urlParams?.tab))} Users`} />}
        headerDivider={false}
        body={
          <UsersTable
            urlParams={urlParams}
            setUrlParams={setUrlParams}
            tableProperties={{
              pagination,
              noPadding: true,
            }}
            users={users?.length ? users : []}
            loadingData={loading}
            deleteAction={(user) => openUserModal(MODAL_ACTIONS.DELETE, user)}
            editAction={(user) => openUserModal(MODAL_ACTIONS.EDIT, user)}
            resendInvitationAction={(user) => openUserModal(MODAL_ACTIONS.CUSTOM, user)}
            reset2FA={(user) => openUserModal(MODAL_ACTIONS.CUSTOM, user, true)}
          />
        }
      />
    </>
  );
};
