import CircularProgress from 'components/CircularProgress';
import { StyledRebalancePageBody } from 'pages/Rebalance/RebalancePage.styles';
import { ReactComponent as NoContent } from 'assets/no-content.svg';
import { Box, Typography } from '@mui/material';

type RebalancePageBodyProps = {
  isLoading: boolean;
  children?: React.ReactNode;
  noContent?: boolean;
  noContentLabel?: string;
};

const EmptyBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={1}
      width="100%"
      height="84px"
    >
      {children}
    </Box>
  );
};

export const RebalancePageBody = ({
  isLoading,
  children,
  noContent,
  noContentLabel,
}: RebalancePageBodyProps) => {
  if (isLoading)
    return (
      <EmptyBox>
        <CircularProgress />
      </EmptyBox>
    );

  if (noContent)
    return (
      <EmptyBox>
        <NoContent />
        <Typography variant="bodyMedium">{noContentLabel ?? 'No content to display'}</Typography>
      </EmptyBox>
    );

  return <StyledRebalancePageBody>{children}</StyledRebalancePageBody>;
};
