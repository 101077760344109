import { TableShowMoreCell } from 'components/Table/TableShowMoreCell';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { Contact, getContactType } from 'utils/types/contacts';
import { MuiStyledTableCellChevron } from 'shared/Tables/ContactsTable/ContactsTable.styles';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';

interface PartnerContactsDetailsTableRowProps {
  contact: Contact;
  deleteAction: () => void;
  editAction: () => void;
}

export const PartnerContactsDetailsTableRow = ({
  editAction,
  deleteAction,
  contact,
}: PartnerContactsDetailsTableRowProps) => {
  return (
    <TableRow onClick={() => contact._actions?.update && editAction()}>
      <MuiStyledTableCellChevron></MuiStyledTableCellChevron>
      <TableCell>{contact.name}Test</TableCell>
      <TableCell>{contact.email}</TableCell>
      <TableCell>{getContactType(contact.type)}</TableCell>
      <TableShowMoreCell items={contact?.contactLists.map(({ name }) => name)} />
      <TableCell
        data-qa-id="editIcons"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {contact._actions?.delete && (
          <TrashIcon
            data-qa-id="trashIcon"
            onClick={() => {
              deleteAction();
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
