import { Button } from 'components/Button/Button';
import { Box, Stack, TableBody, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import copyToClipboard from 'utils/copyToClipboard';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import Tooltip from 'components/Tooltip';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { TableHeaderColumn } from 'components/Table/Table.types';
import { WalletInfo, Order, ProductType } from 'utils/types';
import Tag from 'components/Tag/Tag';

const AuthorizedMerchantColumnsConfig = [
  { label: 'AM Wallets', propName: 'apWallets', propType: 'string', width: '80%', sort: false },
  { label: '', propName: 'actions', propType: 'actions', width: '20%' },
];

const AuthorizedParticipantColumnsConfig = [
  { label: 'AP Wallets', propName: 'apWallets', propType: 'string', width: '80%', sort: false },
  { label: '', propName: 'actions', propType: 'actions', width: '20%' },
];

const WalletsIssuerColumnsConfig = [
  {
    label: 'Issuer Wallets',
    propName: 'issuerWallets',
    propType: 'string',
    width: '80%',
    sort: false,
  },
  { label: '', propName: 'actions', propType: 'actions', width: '20%' },
];

interface WalletsInformationProps {
  productType: ProductType;
  className?: string;
  order?: Order;
}

const WalletTable = ({
  isEtpWallet,
  columns,
  wallets,
}: {
  isEtpWallet?: boolean;
  columns: TableHeaderColumn[];
  wallets?: WalletInfo[];
}) => {
  return (
    <Table noPadding={true}>
      <TableHeaderWithMultiSort columns={columns} />
      <TableBody>
        {wallets?.map((wallet: WalletInfo) => (
          <TableRow key={`wallet-${wallet.ticker}`} hoverEffects={false}>
            <TableCell>
              <Stack alignItems="center" direction="row" gap={1}>
                <Tag label={wallet.ticker} variant="inverse" />
                <Typography variant={'bodyMediumStrong'}>{wallet.address}</Typography>
                {!isEtpWallet && (
                  <Tooltip
                    placement="top"
                    bgColor="black"
                    title={<Box sx={{ padding: 1, color: 'white' }}>{wallet.description}</Box>}
                  />
                )}
                {wallet.purpose && <Tag label={wallet.purpose} variant="default" />}
              </Stack>
            </TableCell>
            <TableCell align="right">
              <Button
                data-html2canvas-ignore
                onClick={() => wallet.address && copyToClipboard(wallet.address)}
              >
                <CopyIcon />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const EmptyWalletTable = ({ columns }: { columns: TableHeaderColumn[] }) => {
  return (
    <Table noPadding={true}>
      <TableHeaderWithMultiSort columns={[columns[0]]} />
      <TableBody>
        <TableRow hoverEffects={false}>
          <TableCell>
            <Typography variant="labelMedium" color={'#838F98'}>
              Wallets will appear once product is selected
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const WalletsInformation = ({ productType, className, order }: WalletsInformationProps) => {
  const showWallets =
    Boolean(order?.issuerWallets?.length) || Boolean(order?.authorizedParticipantWallets?.length);

  return isEmpty(order?.product) || !showWallets ? (
    <>
      <EmptyWalletTable
        columns={
          productType === 'ETP'
            ? AuthorizedParticipantColumnsConfig
            : AuthorizedMerchantColumnsConfig
        }
      />
      <EmptyWalletTable columns={WalletsIssuerColumnsConfig} />
    </>
  ) : (
    <section className={className}>
      {Boolean(order?.authorizedParticipantWallets?.length) && (
        <WalletTable
          wallets={order?.authorizedParticipantWallets}
          columns={
            productType === 'ETP'
              ? AuthorizedParticipantColumnsConfig
              : AuthorizedMerchantColumnsConfig
          }
          isEtpWallet={productType === 'ETP'}
        />
      )}
      {Boolean(order?.issuerWallets?.length) && (
        <WalletTable
          isEtpWallet={productType === 'ETP'}
          wallets={order?.issuerWallets}
          columns={WalletsIssuerColumnsConfig}
        />
      )}
    </section>
  );
};

export default WalletsInformation;
