import { Theme } from '@mui/material/styles';
import { AutocompleteProps, autocompleteClasses } from '@mui/material/Autocomplete';
import { menuItem, paper } from 'theme/css';
import Chip from '@mui/material/Chip';

export function autocomplete(theme: Theme) {
  const getSizeStyles = (
    ownerState: AutocompleteProps<string, false, false, false, typeof Chip, 'default'>
  ) => {
    let height;

    switch (ownerState.size) {
      case 'small':
        height = 32;
        break;
      case 'large':
        height = 48;
        break;
      default: // medium
        height = 40;
    }

    return {
      height,
    };
  };

  const getPaddingStyles = (
    ownerState: AutocompleteProps<string, false, false, false, typeof Chip, 'default'>
  ) => {
    let padding;

    switch (ownerState.size) {
      case 'small':
        padding = '4px 8px';
        break;
      case 'large':
        padding = '8px 8px';
        break;
      default: // medium
        padding = '6px 8px';
    }

    return {
      padding,
    };
  };

  const getDropdownVariantStyles = (
    dropdownVariant: 'small' | 'default' | 'large',
    alignment: 'left' | 'right' = 'left' // default to 'left'
  ) => {
    const baseStyles = {
      minWidth: dropdownVariant === 'small' ? '296px' : '100%', // specific minWidth for small
      width: dropdownVariant === 'large' ? '100%' : 'auto', // full width for large, auto otherwise
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    };

    const alignmentStyles =
      alignment === 'right'
        ? {
            right: 0,
            position: 'absolute',
            transform: 'translateX(0)',
          }
        : {
            left: 0,
            position: 'absolute',
            transform: 'translateX(0)',
          };

    return { ...baseStyles, ...alignmentStyles };
  };

  return {
    MuiAutocomplete: {
      defaultProps: {
        disableElevation: true,
        size: 'medium',
        dropdownVariant: 'default',
        alignment: 'left',
      },
      styleOverrides: {
        root: {
          [`& span.${autocompleteClasses.tag}`]: {
            ...theme.typography.bodyMedium,
          },
        },
        inputRoot: ({
          ownerState,
        }: {
          ownerState: AutocompleteProps<string, false, false, false, typeof Chip, 'default'>;
        }) => ({
          ...getSizeStyles(ownerState),
          ...getPaddingStyles(ownerState),
          '&:focus-within': {
            ...(ownerState.size !== 'small' && { height: 'auto' }),
            ...getPaddingStyles(ownerState),
          },
          alignContent: 'center',
          flexWrap: 'wrap',
          alignItems: 'center',
          '& .MuiIconButton-root': {
            height: '32px',
            width: '32px',
          },
        }),
        paper: ({
          ownerState,
        }: {
          ownerState: {
            dropdownVariant: 'small' | 'default' | 'large';
            alignment: 'left' | 'right';
          };
        }) => ({
          ...getDropdownVariantStyles(ownerState.dropdownVariant, ownerState.alignment),
          ...paper({ theme, dropdown: true }),
        }),
        listbox: {
          padding: 0,
          [`& .${autocompleteClasses.option}`]: {
            ...menuItem(theme),
          },
        },
      },
    },
  };
}
