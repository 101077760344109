import Notification from 'components/Notification';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { FeesDetailsTableColumnsConfig } from './tableColumnsConfig';
import { FeesDetailsTableRow } from './FeesDetailsTableRow';
import { TransactionLedger } from 'utils/types/nav';

interface FeesTableProps {
  fees: TransactionLedger[];
  loadingData?: boolean;
  dateRangeHasHoliday?: boolean;
}

export const FeesDetailsTable = ({ fees, loadingData, dateRangeHasHoliday }: FeesTableProps) => {
  const noContentLabel = 'No Management Fees for the selected date.';

  return (
    <>
      {dateRangeHasHoliday === true && (
        <Notification
          messageMaxWidth={true}
          message="There is a low number of PCFs shown here due to a holiday within this date range."
          multiline={'false'}
          title="Decreased number of PCFs"
          variant="info"
          gapBelow={16}
        />
      )}
      <Table noPadding>
        <TableHeaderWithMultiSort
          columns={FeesDetailsTableColumnsConfig}
          columnAlignment={{ date: 'right', amount: 'right' }}
        />
        <TableBodyWithStates
          loadingData={Boolean(loadingData)}
          hasContent={!isEmpty(fees)}
          noContentLabel={<>{noContentLabel}</>}
        >
          {Object.values(fees)?.map((fee) => (
            <FeesDetailsTableRow
              key={fee._id}
              fee={fee}
              checked={true}
              columns={FeesDetailsTableColumnsConfig}
            />
          ))}
        </TableBodyWithStates>
      </Table>
    </>
  );
};
