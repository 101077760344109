import { styled as MuiStyled } from '@mui/material';

interface StyledCardFooterProps {
  exiticon?: 'true' | 'false';
}

export const StyledCardFooter = MuiStyled('footer')<StyledCardFooterProps>`
  display: flex;
  gap: ${({ theme }) => theme.spacers['spacing-08']};
  padding-top: ${({ theme }) => theme.spacers['spacing-16']};
  ${({ exiticon = 'false' }) => exiticon === 'true' && { 'padding-top': '32px' }};
`;
