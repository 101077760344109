import { TableCell } from 'components/Table/TableCell';
import { TableShowMoreCell } from 'components/Table/TableShowMoreCell';
import { ReactComponent as PencilIcon } from 'assets/pencil-alt.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { Contact, getContactType } from 'utils/types/contacts';
import { TableRow } from 'components/Table/TableRow';

interface YourContactsTableRowProps {
  contact: Contact;
  editAction: (contact: Contact) => void;
  deleteAction: (contact: Contact) => void;
  showActionsCell?: boolean;
}

export const YourContactsTableRow = ({
  contact,
  editAction,
  deleteAction,
}: YourContactsTableRowProps) => {
  return (
    <TableRow key={contact.email} data-qa-id={`${contact.name}-row`}>
      <TableCell data-qa-id={`name-${contact.name}`}>{contact.name}</TableCell>
      <TableCell data-qa-id={`email-${contact.email}`}>{contact.email}</TableCell>
      <TableCell data-qa-id={`type-${contact.type}`}>{getContactType(contact.type)}</TableCell>
      <TableShowMoreCell
        items={contact?.contactLists.map(({ name }) => name)}
        data-qa-id={`distributionList-${contact.contactLists}`}
      />
      <TableCell
        data-qa-id="editIcons"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {contact._actions?.update && (
          <PencilIcon
            data-qa-id="editButton"
            onClick={() => {
              editAction(contact);
            }}
            className="editIcons"
          />
        )}
        {contact._actions?.delete && (
          <TrashIcon
            data-qa-id="trashButton"
            onClick={() => {
              deleteAction(contact);
            }}
            className="editIcons"
          />
        )}
      </TableCell>
    </TableRow>
  );
};
