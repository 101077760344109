import { Button } from 'components/Button/Button';
import Table from 'components/Table';
import useOnSubmit from 'pages/Tokens/hooks/useOnSubmit';
import {
  CUSTODIAN_INFO_STEP,
  PARTNERS_STEP,
  WALLETS_INFO_STEP,
  TokenPartnerDetailsStepType,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.steps';
import { Column, Row } from 'components/Grid';
import { Fragment } from 'react';
import { Box, IconButton, Stack, TableBody, Typography, useTheme } from '@mui/material';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { ReactComponent as PencilIcon } from 'assets/pencil-alt.svg';
import { StyledButtonRow } from 'pages/Tokens/components/Form/TokenDetails/components/SummaryStep/SummaryStep.styles';
import { StyledColumn, StyledEntityName } from './ReviewStep.styles';
import { capitalize, groupBy } from 'lodash';
import { mapPartners } from './helpers';
import { useRequestCompaniesQuery } from 'pages/Admin/ContactsPage/hooks/api/useRequestCompaniesQuery';
import { useTokenContext } from 'contexts/tokens';
import { useUserPermissions } from 'store/user/selectors';
import { ReviewStepColumnsConfig } from 'pages/Instruments/components/Form/PartnerDetails/components/ReviewStep/ReviewStepColumnsConfig';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';

interface ReviewStepProps {
  goBack: () => void;
  goToStep: (step: TokenPartnerDetailsStepType) => void;
}

const ProductReviewHeader = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        height: '64px',
        width: '100%',
        padding: theme.spacing(2),
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  );
};

function ReviewStep({ goBack, goToStep }: ReviewStepProps) {
  const { data: partnersData } = useRequestCompaniesQuery(true);
  const partners = partnersData?.data.data ?? [];
  const permissions = useUserPermissions(); // @TODO TOKENS - add when permissions are added to BE

  const { currentToken, tokenFormsData } = useTokenContext();
  const actions = currentToken?._actions;
  const { onSubmitForApproval, onActivateToken } = useOnSubmit(currentToken);

  const wallets = [
    ...(tokenFormsData[WALLETS_INFO_STEP]?.custodianWallets || []),
    ...(tokenFormsData[WALLETS_INFO_STEP]?.unifiedWallets || []),
  ];

  return (
    <>
      <ProductReviewHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Partners" />
          <IconButton onClick={() => goToStep(PARTNERS_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBody>
          <TableRow>
            <TableCell>Issuer</TableCell>
            <TableCell>
              <StyledEntityName>
                {
                  partners.find((partner) => partner._id === tokenFormsData[PARTNERS_STEP]?.issuer)
                    ?.name
                }
              </StyledEntityName>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Authorized Merchants</TableCell>
            <TableCell>
              {mapPartners(tokenFormsData[PARTNERS_STEP]?.authorizedParticipants, partners)?.map(
                (name) => (
                  <StyledEntityName key={name}>{name};</StyledEntityName>
                )
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Extra Providers</TableCell>
            <TableCell>
              {tokenFormsData[PARTNERS_STEP]?.custodians?.map(capitalize)?.map((name) => (
                <StyledEntityName key={name}>{name};</StyledEntityName>
              ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Row>
        <StyledColumn cols={12}>
          <ProductConfigHeader title="Custodian Info" />
          <Button variant="inverse" onClick={() => goToStep(CUSTODIAN_INFO_STEP)}>
            <PencilIcon />
          </Button>
        </StyledColumn>
      </Row>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBody>
          <TableRow>
            <TableCell>Custodians Accounts</TableCell>
            <TableCell>
              {tokenFormsData[CUSTODIAN_INFO_STEP]?.map((custodian) => (
                <StyledEntityName key={custodian._id}>{custodian.name};</StyledEntityName>
              ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Row>
        <StyledColumn cols={12}>
          <ProductConfigHeader title="Wallets Info" />
          <Button variant="inverse" onClick={() => goToStep(WALLETS_INFO_STEP)}>
            <PencilIcon />
          </Button>
        </StyledColumn>
      </Row>
      {Object.entries(groupBy(wallets, 'chain')).map(([chain, walletsInfo], index) => (
        <Fragment key={chain}>
          <Row>
            <Column cols={12}>
              <Typography variant="headingSmall">{chain} Wallets</Typography>
            </Column>
          </Row>
          <Table>
            <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
            <TableBody>
              <TableRow>
                <TableCell>Wallet</TableCell>
                <TableCell>
                  {walletsInfo.map((wallet) => (
                    <StyledEntityName key={wallet._id}>{wallet?.description};</StyledEntityName>
                  ))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Fragment>
      ))}
      <StyledButtonRow>
        <StyledColumn cols={2}>
          <Button fullWidth type="button" variant="secondary" onClick={goBack}>
            Back
          </Button>
        </StyledColumn>
        {Boolean(permissions?.canEditToken && actions?.submitForApproval) && (
          <StyledColumn cols={2}>
            <Button fullWidth type="button" variant="primary" onClick={onSubmitForApproval}>
              Ready for Review
            </Button>
          </StyledColumn>
        )}
        {Boolean(
          permissions?.canApproveToken && actions?.activate && !actions?.submitForApproval
        ) && (
          <StyledColumn cols={2}>
            <Button fullWidth type="button" variant="primary" onClick={onActivateToken}>
              Activate
            </Button>
          </StyledColumn>
        )}
      </StyledButtonRow>
    </>
  );
}

export default ReviewStep;
