import React, { ComponentPropsWithoutRef } from 'react';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormLabel,
  TextField,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  GroupedOptionsType,
  OptionType,
  OptionValueType,
  OnChangeType,
} from 'components/Select/Select.types';
import transformOptions from 'components/Select/helpers/transformOptions';
import Tag from 'components/Tag/Tag';
import { AutocompleteRenderOptionState } from '@mui/material';

export interface MultiSelectProps<T extends OptionValueType>
  extends Omit<ComponentPropsWithoutRef<'select'>, 'onChange' | 'value' | 'size'> {
  ['data-qa-id']?: string;
  ['data-qa-options-id']?: string;
  errorMessage?: string;
  filteredOptions?: OptionType<T>[] | GroupedOptionsType<T>;
  fullWidth?: boolean;
  isInvalid?: boolean;
  label?: string;
  minWidth?: string | number;
  name?: string;
  onChange?: OnChangeType<T>;
  onFilter?: (inputValue?: string) => void;
  options?: OptionType<T>[] | GroupedOptionsType<T>;
  showMaxValues?: number;
  size?: 'small' | 'medium' | 'large';
  value?: string[];
  variantOptions?: 'chip' | 'li';
  width?: string;
  withFilter?: boolean;
  dropdownVariant?: 'small' | 'default' | 'large';
  alignment?: 'left' | 'right';
  renderTagsAsString?: boolean;
}

export const MultiSelect = <T extends OptionValueType>({
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
  className,
  disabled,
  errorMessage,
  fullWidth = false,
  isInvalid,
  label,
  minWidth = '200px',
  name,
  onChange,
  options,
  placeholder,
  showMaxValues = 3,
  size = 'medium',
  value = [],
  variantOptions = 'li',
  width,
  dropdownVariant = 'default',
  alignment = 'left',
  renderTagsAsString = false,
  ...props
}: MultiSelectProps<T>) => {
  const items = transformOptions(options);
  const selectedItems = items.filter((item) => {
    return item.value !== undefined && value.some((v) => v !== undefined && v === item.value);
  });

  const handleOnChange = (event: React.SyntheticEvent, selectedOptions: OptionType<T>[]) => {
    const selectedValues = selectedOptions.map((option) => option.value as T);
    const primarySelection = selectedValues[0];
    onChange && onChange(primarySelection, selectedValues);
  };

  const handleOnOptionSelect = (
    event: React.MouseEvent,
    option: OptionType<T>,
    selected: boolean
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const newSelectedItems = selected
      ? selectedItems.filter((item) => item.value !== option.value)
      : [...selectedItems, option];
    handleOnChange(event, newSelectedItems);
  };

  const isOptionEqualToValue = (option: OptionType<T>, value: OptionType<T>) => {
    return option.value === value.value;
  };

  const renderTagsFn = renderTagsAsString
    ? (value: OptionType<T>[]) => {
        // Render tags as a string with an overflow counter
        const displayedOptions = value.slice(0, showMaxValues);
        const overflowCount = value.length - showMaxValues;
        const displayedLabels = displayedOptions.map((option) => option.label).join(', ');

        return (
          <Typography variant="body2">
            {displayedLabels}
            {overflowCount > 0 && `, +${overflowCount}`}
          </Typography>
        );
      }
    : // Render tags
      (value: OptionType<T>[], getTagProps: any) =>
        value.map((option: OptionType<T>, index: number) => (
          <Tag label={option.label} variant="default" {...getTagProps({ index })} />
        ));

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={isInvalid}
      data-qa-id={dataQaId}
      disabled={disabled}
    >
      {label && <FormLabel sx={{ marginBottom: '4px' }}>{label}</FormLabel>}
      <Autocomplete
        limitTags={showMaxValues}
        size={size}
        dropdownVariant={dropdownVariant}
        alignment={alignment}
        data-qa-id={dataQaId}
        multiple
        disabled={disabled}
        id="checkboxes-tags-demo"
        options={items}
        disableCloseOnSelect
        getOptionLabel={(option: OptionType<T>) => option.label}
        value={selectedItems}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={handleOnChange}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label=""
            placeholder={value.length ? '' : placeholder}
            InputProps={{
              ...params.InputProps,
              sx: {
                padding: '4px',
              },
            }}
          />
        )}
        renderOption={(props: any, option: OptionType<T>, state: AutocompleteRenderOptionState) => {
          const { selected } = state;
          const { onClick, ...optionProps } = props;
          return variantOptions === 'chip' ? (
            <Tag
              label={option.label}
              onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                handleOnOptionSelect(event, option, selected)
              }
              sx={{
                '& .MuiChip-root': { height: '32px' },
                m: 0.5,
                backgroundColor: selected ? 'primary.main' : 'action.selected',
                color: selected ? 'primary.contrastText' : 'text.primary',
                '&:hover': {
                  backgroundColor: selected ? 'primary.dark' : 'action.hover',
                },
              }}
            />
          ) : (
            <MenuItem
              style={{ margin: '3px' }}
              {...optionProps}
              onClick={(event) => handleOnOptionSelect(event, option, selected)}
              data-qa-options-id={dataQaOptionsId}
            >
              <Checkbox checked={selected} />
              <Typography variant="labelMedium">{option.label}</Typography>
            </MenuItem>
          );
        }}
        renderTags={renderTagsFn}
        sx={{
          width: fullWidth ? '100%' : undefined,
          minWidth: minWidth,
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            paddingLeft: renderTagsAsString ? '16px' : '6px',
          },
        }}
      />
    </FormControl>
  );
};

export default MultiSelect;
