import { Button } from 'components/Button/Button';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  StyledContentWrapper,
  StyledLink,
  StyledLoginContainer,
  StyledLoginFormWrapper,
  StyledLoginLogoWrapper,
  StyledNeedHelp,
  StyledReviewTermsOfUse,
} from 'pages/Login/Login.styles';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { authLogin } from 'utils/api/auth';
import { contactOnyxSupport } from 'utils/externalLinks';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { loginSchemaUrl } from 'components/Form/formSchemas';
import { privateWeakRoutesUrls, publicRoutesUrls } from 'router/constants';
import { storeWeakToken } from 'utils/user/storage';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import FormGridItem from 'components/Form/components/FormGridItem';
import { Typography } from '@mui/material';

const INVALID_CREDENTIALS_ERROR_MESSAGE = 'Invalid credentials';

function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loginEmailValue, setLoginEmailValue] = useState<string>();

  const autthLoginMutation = useMutation({
    mutationFn: authLogin,
    onSuccess: (data) => {
      const loginResponse = data.data;
      storeWeakToken(loginResponse.weakToken);
      if (loginResponse._actions['2fa']) {
        navigate(privateWeakRoutesUrls.twoFa, {
          state: loginResponse._actions['2fa'],
        });
      } else if (loginResponse._actions.request2fa) {
        navigate(privateWeakRoutesUrls.twoFAsetup, {
          state: loginResponse._actions.request2fa,
        });
      }
    },
    onError: (error: Error) => {
      if (error.message === INVALID_CREDENTIALS_ERROR_MESSAGE) {
        dispatch(
          createNotification(
            errorNotification(
              'The email or password entered did not match our records. Please double-check and try again.'
            ),
            error
          )
        );
      } else {
        dispatch(createNotification(errorNotification(), error));
      }
    },
  });

  const handleOnChange = (values: FieldValues) => {
    setLoginEmailValue(values.email);
  };
  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    autthLoginMutation.mutate(values);
  };

  return (
    <StyledLoginContainer>
      <StyledContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        <StyledLoginFormWrapper>
          <Form schemaUrl={loginSchemaUrl} onSubmit={onSubmit} onChange={handleOnChange}>
            <FormGridContainer padding={0}>
              <FormGridItem>
                <Typography variant="subheadingMedium">Login</Typography>
              </FormGridItem>
              <FormGridItem md={12}>Welcome to Onyx! Please enter your credentials.</FormGridItem>
              <FormGridItem md={12}>
                <Input name="email" type="email" />
              </FormGridItem>
              <FormGridItem md={12}>
                <Input
                  name="password"
                  inputType="password"
                  nodeLabel={
                    <StyledLink
                      data-qa-id="forgetPassword"
                      onClick={() =>
                        navigate(publicRoutesUrls.updatePassword, {
                          state: { loginEmailValue },
                        })
                      }
                    >
                      Forgot Password?
                    </StyledLink>
                  }
                />
              </FormGridItem>
              <FormGridItem md={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                  data-qa-id="logInButton"
                  disabled={autthLoginMutation.isLoading}
                  fullWidth
                  variant="primary"
                  type="submit"
                  style={{ width: '50%' }}
                >
                  Log in
                </Button>
              </FormGridItem>
            </FormGridContainer>
          </Form>
        </StyledLoginFormWrapper>
        <StyledNeedHelp cols={12}>
          Need Help?{' '}
          <a href={contactOnyxSupport} target="_blank" rel="noreferrer">
            Contact Onyx support.
          </a>
        </StyledNeedHelp>
        <StyledReviewTermsOfUse onClick={() => navigate(publicRoutesUrls.termsOfUse)}>
          Review our Terms of Use
        </StyledReviewTermsOfUse>
      </StyledContentWrapper>
    </StyledLoginContainer>
  );
}

export default Login;
