import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import { CONTACT_LIST_TYPE_LABEL, ContactList } from 'utils/types/contacts';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

interface ContactsListTableRowProps {
  editAction: () => void;
  editListInfoAction: () => void;
  contactList: ContactList;
  addContactsToListAction: () => void;
  deleteListAction: () => void;
}

const ContactsListTableRow = ({
  editAction,
  contactList,
  editListInfoAction,
  addContactsToListAction,
  deleteListAction,
}: ContactsListTableRowProps) => {
  const actions = contactList._actions;

  return (
    <TableRow data-qa-id={`${contactList.name}-row`} key={contactList._id}>
      <TableCell data-qa-id={`${contactList.name}-name`} onClick={() => editAction()} pointer>
        {contactList.name}
      </TableCell>
      <TableCell
        data-qa-id={`${contactList.name}-type`}
        onClick={() => editAction()}
        pointer
        align={'right'}
      >
        {CONTACT_LIST_TYPE_LABEL[contactList.type] ?? contactList.type}
      </TableCell>
      <TableCell
        data-qa-id={`${contactList.name}-count`}
        onClick={() => editAction()}
        pointer
        align={'right'}
      >
        {contactList.contactsCount}
      </TableCell>
      <TableCell data-qa-id="editIcons">
        <ButtonDropdown id={contactList._id ?? ''} className="actionsCell">
          {actions?.update && (
            <ButtonDropdownItem
              id="editContactsList"
              label="Edit Distribution List"
              onClick={editListInfoAction}
            />
          )}
          {actions?.assign && (
            <ButtonDropdownItem
              id="addContactToContactsList"
              label="Add contact to Distribution List"
              onClick={addContactsToListAction}
            />
          )}
          {actions?.delete && (
            <ButtonDropdownItem
              id="deleteContactsList"
              label="Delete Distribution List"
              onClick={deleteListAction}
            />
          )}
        </ButtonDropdown>
      </TableCell>
    </TableRow>
  );
};

export default ContactsListTableRow;
