export const FeesTableColumnsConfig = [
  { label: 'Product', propName: 'product.ticker', propType: 'string', width: '36%' },
  {
    label: 'Constituent',
    propName: 'constituent',
    propType: 'string',
    sort: false,
    width: '35%',
  },
  {
    label: 'Total Unrealized Accrued Fee',
    propName: 'totalUnrealizedAccruedFee',
    propType: 'date',
    width: '25%',
    sort: false, // We can't sort this column because it's calculated in the backend
  },
];
