import { IModalWithData } from 'shared/Modals/types';
import { ReactNode } from 'react';
import { FeesWithdrawBulkFeesModal } from './FeesWithdrawBulkFeesModal/FeesWithdrawBulkFeesModal';

export const FeesModal = ({ ...props }: IModalWithData) => {
  const FeesModals: Record<string, ReactNode> = {
    CUSTOM: <FeesWithdrawBulkFeesModal {...props} />,
  };

  return <>{FeesModals[props.data.type]}</>;
};
