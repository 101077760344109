import { TableCell } from 'components/Table';
import { Box, styled as MuiStyled, Table } from '@mui/material';
import fontTypes from 'utils/theme/designTokens/fontTypes';

interface ColoredItemProps {
  color?: 'main' | 'success' | 'error';
}

export const StyledRebalancePageBody = MuiStyled(Box)`
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
  gap: 20px;
`;

export const StyledRebalanceTradesCard = MuiStyled(Box)`
  background: white;
  display: flex;
  flex-direction: column;
  border: 1px #e6e6e6 solid;
`;

export const StyledTradeTableWrapper = MuiStyled(Box)`
  height: 240px;
  overflow: auto;
  flex-grow: 2;
`;

export const StyledRebalancesHeader = MuiStyled(Box)`
  height: 90px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  background: white;
  padding-inline: 8px;
`;

export const StyledHeaderTitleComponent = MuiStyled(Box)`
  height: 26px;
  display: flex;
  width: 104px;
  gap: 8px;
  svg {
    align-self: center;
  }
`;

export const StyledHeaderNotes = MuiStyled(Box)`
  line-height: 16px;
  display: flex;
  height: 48px;
  padding: 16px 0;
  gap: 16px;
`;

export const StyledHeaderTitleWrapper = MuiStyled(Box)`
  line-height: 28px;
  height: 42px;
  padding-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledRebalancesCardFooterWrapper = MuiStyled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-inline: 16px;
  width: 50%;
`;

export const StyledRebalancesCardFooterButtonWrapper = MuiStyled(Box)`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 16px 0;
  height: 72px;
  button {
    width: 50%;
  }
`;

export const StyledColoredTableCell = MuiStyled(TableCell)<ColoredItemProps>`
  ${({ color, theme }) => color && { color: theme.palette.cellRange[color] }};
`;

export const StyledRebalancePageDetails = MuiStyled(Box)`
  gap: 32px;
`;

export const StyledRebalanceDetailsContainer = MuiStyled('section')`
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  overflow-y: auto;
  gap: 16px;
`;

export const StyledNotificationContainer = MuiStyled(Box)`
  margin-top: 16px;
  padding-inline: 16px;
`;

export const StyledTitle = MuiStyled(Box)`
  ${() => fontTypes['heading-03']};
  color: ${({ theme }) => theme.palette.secondary.light};
  margin-block: 8px;
`;

export const StyledRebalanceTable = MuiStyled(Table)(({ theme }) => ({
  '.MuiTableCell-root': {
    padding: '0px 16px',
  },
}));
