import {
  CUSTODIAN_INFO_STEP,
  PARTNERS_STEP,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import {
  CustodiansListProps,
  PartnerDetailsFormProps,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import isEmpty from 'lodash/isEmpty';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppModal from 'hooks/useAppModal';
import { CONSTITUENT_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { CustodianStatus } from 'utils/constants/custodians';
import { ETPWalletsTableRow } from './ETPWalletsTableRow';
import { ETPsWalletsTableColumnsConfig } from './tableColumnsConfig';
import { MODAL_ACTIONS, WALLET_MODAL } from 'shared/Modals/constants';
import { CompanySuggestionDto } from 'utils/types';
import { CustodianWalletType, ProductCustodianWallet, WalletType } from 'utils/types/wallets';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import Button from 'components/Button';
import { WALLETS_TABS_STATUS } from 'utils/constants/wallets';
import { WalletsInfoStepTabs } from 'pages/Instruments/components/Form/PartnerDetails/components/WalletsInfoStep/components/WalletsInfoStepTabs';
import { createNotification } from 'store/notifications/actions';
import { infoNotification } from 'shared/Notifications/general.notifications';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import Box from '@mui/material/Box';
import { useCallback, useMemo } from 'react';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';

interface WalletsTableProps {
  instrumentFormsData: PartnerDetailsFormProps;
  loading: boolean;
  onUpdate: () => void;
  partners: CompanySuggestionDto[];
  onChangeTab: (data: WALLETS_TABS_STATUS) => void;
  activeTab: WALLETS_TABS_STATUS;
  wallets: ProductCustodianWallet[];
  custodians: CustodiansListProps;
}

function ETPsWalletsTable({
  loading,
  onUpdate,
  partners,
  instrumentFormsData,
  onChangeTab,
  activeTab,
  wallets,
  custodians,
}: WalletsTableProps) {
  const dispatch = useAppDispatch();
  const openModal = useAppModal();
  const handleChangeTab = (tab: WALLETS_TABS_STATUS) => {
    onChangeTab(tab);
  };

  const openWalletModal = useCallback(
    (
      type: MODAL_ACTIONS,
      walletType: WalletType,
      wallet?: ProductCustodianWallet,
      custodianWalletType?: CustodianWalletType
    ) => {
      if (
        !custodians ||
        custodians?.length === 0 ||
        instrumentFormsData[CONSTITUENT_STEP]?.length === 0 ||
        !instrumentFormsData[CONSTITUENT_STEP]
      ) {
        dispatch(
          createNotification(
            infoNotification("You can't add wallet if custodians or constituents are empty")
          )
        );
      } else {
        openModal(
          {
            modalName: WALLET_MODAL,
            modalData: {
              data: {
                wallet,
                type: walletType,
                custodianWalletType: custodianWalletType,
                productData: {
                  authorizedParticiants:
                    instrumentFormsData[PARTNERS_STEP]?.authorizedParticipants ?? [],
                  partners: partners,
                  custodians: custodians?.filter((custodian) => {
                    return custodian.status === CustodianStatus.ACTIVE;
                  }),
                  constituents: instrumentFormsData[CONSTITUENT_STEP],
                },
              },
              companyData: {
                id: (wallet as ProductCustodianWallet)?.transactingCompany ?? '',
                name:
                  partners.find(
                    (partner) =>
                      partner._id === (wallet as ProductCustodianWallet)?.transactingCompany
                  )?.name ?? '',
              },
              type: type,
            },
          },
          {
            onCloseModalAction: () => onUpdate(),
          }
        );
      }
    },
    [custodians, dispatch, instrumentFormsData, onUpdate, openModal, partners]
  );

  const openDeleteWalletModal = (walletType: WalletType, wallet?: ProductCustodianWallet) => {
    openModal(
      {
        modalName: WALLET_MODAL,
        modalData: {
          data: {
            wallet,
            type: walletType,
          },
          companyData: {
            id: (wallet as ProductCustodianWallet)?.transactingCompany,
            name:
              partners.find(
                (partner) => partner._id === (wallet as ProductCustodianWallet)?.transactingCompany
              )?.name ?? '',
          },
          type: MODAL_ACTIONS.DELETE,
        },
      },
      {
        onCloseModalAction: () => onUpdate(),
      }
    );
  };

  const createWalletButton = useMemo(
    () => (
      <ButtonDropdown
        id={'walletsPopover'}
        anchor={
          <Button
            type="button"
            data-qa-id="addWalletButton"
            size="medium"
            key="toolbar-button"
            variant="secondary"
          >
            <span>Add Wallet</span>
            <PlusIcon />
          </Button>
        }
      >
        <ButtonDropdownItem
          id="newCustodianWallet"
          label="Add constituent wallet"
          onClick={() =>
            openWalletModal(
              MODAL_ACTIONS.CREATE,
              WalletType.CUSTODIAN_WALLET,
              undefined,
              CustodianWalletType.CONSTITUENT
            )
          }
        />
        <ButtonDropdownItem
          id="newCompanyWallet"
          label="Add delivery wallet"
          onClick={() =>
            openWalletModal(
              MODAL_ACTIONS.CREATE,
              WalletType.CUSTODIAN_WALLET,
              undefined,
              CustodianWalletType.DELIVERY
            )
          }
        />
      </ButtonDropdown>
    ),
    [openWalletModal]
  );

  return (
    <>
      <Box paddingLeft={2} paddingRight={2}>
        <TableTitleWithActions title="Wallets" actions={[createWalletButton]} />
      </Box>
      <Table
        tabsComponent={
          <Box paddingLeft={2}>
            <WalletsInfoStepTabs
              active={activeTab}
              onChangeTab={handleChangeTab}
            ></WalletsInfoStepTabs>{' '}
          </Box>
        }
      >
        <TableHeaderWithMultiSort showActionsCell={true} columns={ETPsWalletsTableColumnsConfig} />
        <TableBodyWithStates
          loadingData={loading}
          hasContent={!isEmpty(wallets)}
          noContentLabel="No connected wallets."
        >
          {wallets?.map((wallet) => (
            <ETPWalletsTableRow
              key={wallet._id}
              wallet={wallet as ProductCustodianWallet}
              editAction={() => {
                openWalletModal(MODAL_ACTIONS.EDIT, WalletType.CUSTODIAN_WALLET, wallet);
              }}
              deleteAction={() => openDeleteWalletModal(WalletType.CUSTODIAN_WALLET, wallet)}
              activateAction={() => {
                openWalletModal(MODAL_ACTIONS.CUSTOM, WalletType.CUSTODIAN_WALLET, wallet);
              }}
              custodianName={
                instrumentFormsData[CUSTODIAN_INFO_STEP]?.find(
                  (custodian) =>
                    custodian._id === (wallet as ProductCustodianWallet).custodianAccount
                )?.name ?? ''
              }
              partners={partners}
            />
          ))}
        </TableBodyWithStates>
      </Table>
    </>
  );
}

export default ETPsWalletsTable;
