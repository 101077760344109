import ExchangeLocalTickerList from 'pages/Instruments/components/Form/EtpDetails/components/ExchangeStep/components/ExchangeLocalTickerList';
import {
  ExchangeLocalTickerType,
  ExchangeType,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { partial } from 'lodash';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import { Stack, Typography } from '@mui/material';
import Tag from 'components/Tag/Tag';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

interface ExchangeProps {
  productExchange: ExchangeType;
  handleEditProductExchange: (crudAction: MODAL_ACTIONS, productExchange: ExchangeType) => void;
  handleRemoveProductExchange: (productExchange: ExchangeType) => void;
  handleEditProductExchangeTicker: (
    crudAction: MODAL_ACTIONS,
    productExchange: ExchangeType,
    ticker?: ExchangeLocalTickerType
  ) => void;
  handleRemoveProductExchangeTicker: (productExchange: ExchangeType) => void;
}

function ProductExchangeRow({
  productExchange,
  handleEditProductExchange,
  handleEditProductExchangeTicker,
  handleRemoveProductExchangeTicker,
  handleRemoveProductExchange,
}: ExchangeProps) {
  const removeLocalTicker = (productExchange: ExchangeType, localTickerIndex: number) => {
    const productExchangeCopy = { ...productExchange };

    if (productExchangeCopy.localTickers) {
      productExchangeCopy.localTickers = [
        ...productExchangeCopy.localTickers.slice(0, localTickerIndex),
        ...productExchangeCopy.localTickers.slice(localTickerIndex + 1),
      ];
      handleRemoveProductExchangeTicker(productExchangeCopy);
    }
  };

  return (
    <>
      <TableRow
        key={productExchange.id}
        onClick={(event) => {
          event.stopPropagation();
          handleEditProductExchange(MODAL_ACTIONS.EDIT, productExchange);
        }}
      >
        <TableCell>
          <Stack direction="row" alignItems="center">
            <Typography mr={2}>{productExchange.name}</Typography>
            {Boolean(productExchange.isPrimary) && (
              <Tag label={'Primary Exchange'} variant="green" />
            )}
            {Boolean(productExchange.constituentExchange) && (
              <Tag label={'Constituent Exchange'} variant="yellow" />
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <ButtonDropdown id={productExchange.id} className="actionsCell">
            <ButtonDropdownItem
              id="addTicker"
              label="Add Local Ticker"
              onClick={() => handleEditProductExchangeTicker(MODAL_ACTIONS.CUSTOM, productExchange)}
            />
            <ButtonDropdownItem
              id="deleteExchange"
              label="Delete Exchange"
              onClick={() => handleRemoveProductExchange(productExchange)}
            />
          </ButtonDropdown>
        </TableCell>
      </TableRow>
      <ExchangeLocalTickerList
        key={`${productExchange.name}-local-tickers`}
        productExchange={productExchange}
        localTickers={productExchange.localTickers}
        onEdit={(ticker) =>
          handleEditProductExchangeTicker(MODAL_ACTIONS.CUSTOM, productExchange, ticker)
        }
        onDelete={partial(removeLocalTicker, productExchange)}
      />
    </>
  );
}

export default ProductExchangeRow;
